import React from 'react';
import { Button, FormControl, IconButton  } from '@material-ui/core';
import { useHistory } from "react-router";
// import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListIcon from '@material-ui/icons/FilterList';
// import moment from 'moment';
import { searchItems, searchValues, claimsDropdown } from './data';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../assets/icons/foot-calendar.svg';
import MaskedInput from 'react-text-mask';
import './footer.css';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM');

const styles = {
    background: 'linear-gradient(0deg, #24476A 31.25%, #8EAFC2 299.25%)',
    height: 70,
    width: '100%',
    position: 'fixed',
    bottom: 0
}

// const inputStyles = {
//   marginTop: 16, 
//   float: 'left', 
//   height: 28, 
//   width: 270,
//   color: 'black',
//   borderRadius: '10px'
// }

const inputStyle = {
  marginTop: 13, 
  marginLeft:13,
  float: 'left', 
  height: 30, 
  width: 270,
  borderRadius: '10px'
}

const Footer = (props) => {

  let path = window.location.pathname;

  const onDateChange = (event) => {
    const e = {
      target: {
        value: 'ranByDay'
      }
    }
    props.handleClaimsDropdown(e)
    props.onDateChange(event)
  }

  const handleClaimsDropdown = async (e) => {
    await props.handleClaimsDropdown(e)
  }

  // logic to change date format
  // let x = document.querySelector('#inputDate');
  // let formattedDate;
  // if(x){
  //   x.addEventListener('change', function() {
  //     formattedDate = moment(this.value, "YYYY-MM-DD").format(this.getAttribute('data-date-format'));
  //     this.setAttribute('data-date',
  //       moment(this.value, "YYYY-MM-DD").format(this.getAttribute('data-date-format')))
  //       // console.log(this.value, formattedDate);
  //   })
  //   x.dispatchEvent(new Event("change"));
  // }

  return (
    <div style={styles}>
      {(path === '/rule-processor' || path === '/runRules')  && ( 
      <div className="row">
        <div className="col-6 pt-2">
          <div className="form-inline">
            <div className='footer-left-area'>
              <div className='t-left'>
                {/* <label htmlFor="select" className="text-white" style={{textAlign: 'left'}}>Search:</label> */}
                {/* <select className="p-1" onChange={props.onSelectChange}>
                  <option value="">Select</option>
                  {searchItems.map((item, key) => (<>
                    <option key={key} value={item.key}>{item.value}</option>
                    </>))}
                  </select> */}
                <select 
                className={props.optionSelector === true ? 'sticky-selection2 show-selector' : 'sticky-selection2 none-selector'} 
                onChange={props.onSelectChange} multiple>
                  {searchItems.map((item, key) => (<>
                    <option className='p-1 option text-center font-face-futura-footer' key={key} value={item.key}>{item.value}</option>
                    </>))}
                </select>
                <IconButton onClick={props.selectHandler}>
                  <FilterListIcon
                  style={{backgroundColor: 'white', width: 32, height: 30, borderRadius: '50%', color: '#535353', marginTop: 1}} />
                </IconButton>
              </div>            
              <div>
                <input type="text" className='p-2 searchInput font-face-futura-placeholder'
                value={props.searchValue} 
                placeholder={props.draftPlaceholder ? 'Search by' + ' ' + props.draftPlaceholder : 'Search by Primary Insurance'} 
                onChange={props.onInputChange} />
              </div>

              <select className={props.claimSelector === true ? 'sticky-select show-selector' : 'sticky-select none-selector'}
              onChange={props.onClaimChange} multiple>
                {claimsDropdown.map((claim, key) => (
                  <option className='p-2 option text-center font-face-futura-footer' selected={props.claimsDropdownSelect === claim.key ? true : false } onClick={handleClaimsDropdown} key={key} value={claim.key}>{claim.value}</option>
                ))}
              </select>
              <div onClick={props.claimHandler}>
                <label htmlFor='claims-input' className='claimsDrop'>
                  <input className='claimInput font-face-futura-placeholder'
                  type='text' 
                  placeholder={props.labelValue ? props.labelValue : 'Claim Center - Not Ran'} />
                </label>
              </div>
              {/* <div className='claims'>
                <select className='claimsDrop font-face-futura-footer'>
                  {claimsDropdown.map((claim, key) => (
                    <option className='p-2 font-face-futura-footer' key={key} value={claim.key}>{claim.value}</option>
                  ))}
                </select>
              </div> */}
            </div>
          </div>
          <div className='foot-calendar'>
            <DatePicker 
              className='date-picker'
              format={"MM-dd-yyyy"}
              placeholderText="MM/DD/YYYY"
              selected={path === '/rule-processor' ? props.formattedRuleDate : props.rulesDate}
              onChange={path === '/rule-processor' ? (e) => onDateChange(e) : props.handleDateChange}
              customInput={
              <MaskedInput 
              pipe={autoCorrectedDatePipe}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              guide={true}
              keepCharPositions={true} />}
            />
            <span className='mt-1 mx-2'>
              <img src={calendar} alt='foot-calendar' />
            </span>
          </div>
        </div>

        <div className="col-6 pt-2">
          {/* <FormControl style={{float: 'right', marginRight: 120}}> */}
            {/* <label htmlFor="rules" className="text-white" style={{textAlign: 'left'}}>Rules ran on:</label> */}
            <div className='foot-calendar'>
              <DatePicker 
                className='date-picker'
                format={"MM-dd-yyyy"}
                placeholderText="MM/DD/YYYY"
                selected={path === '/rule-processor' ? props.formattedRuleDate : props.rulesDate}
                onChange={path === '/rule-processor' ? (e) => onDateChange(e) : props.handleDateChange}
                customInput={
                <MaskedInput 
                pipe={autoCorrectedDatePipe}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                guide={true}
                keepCharPositions={true} />}
              />
              <span className='mt-1 mx-2'>
                <img src={calendar} alt='foot-calendar' />
              </span>
            </div>
          {/* </FormControl> */}
        </div>
      </div>)}

      {path === '/manageRule' && (
      <div className="row">
        <div className="col-6 pt-2">
          <div className="form-inline">
            <div className='footer-left-area'>
              <div className='t-left'>
                <select
                className={props.selector === true ? 'sticky-selection show-selector' : 'sticky-selection none-selector'} 
                onChange={props.onSelect} multiple>
                  {searchValues.map((item, key) => (<>
                    <option className='p-1 option text-center font-face-futura-footer' key={key} value={item.key}>{item.value}</option>
                    </>))}
                </select>
                <IconButton onClick={props.clickHandler}>
                  <FilterListIcon
                  style={{backgroundColor: 'white', width: 32, height: 30, borderRadius: '50%', color: '#535353', marginTop: 3}} />
                </IconButton>
              </div>
              <div>
                <input 
                type="text" 
                className='p-2 searchInput font-face-futura-placeholder'
                placeholder={props.placeholder ? props.placeholder : 'Search by rule name'} 
                style={inputStyle}
                value={props.ruleSearchValue}
                onChange={props.onInput} 
                />
              </div>
            </div>
          </div>
        </div>
      </div>)}

      {path === '/create-rule' && (<></>)}
    </div>
  );
}

Footer.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  rulesRan: PropTypes.bool.isRequired,
  ruleSuccess: PropTypes.bool.isRequired,
  formattedRuleDate: PropTypes.string.isRequired,
  rulesDate: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  selector: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  clickHandler: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  ruleSearchValue: PropTypes.string.isRequired,
  onInput: PropTypes.func.isRequired,
  handleClaimsDropdown: PropTypes.func.isRequired
}

export default Footer;
