import React from 'react'
import Footer from '../Footer/Footer';
import Sidebar from '../Sidebar';
import { Tabs, Tab, Tooltip, IconButton } from '@material-ui/core';
import API from '../../Api';
import { searchValues } from '../Footer/data';
import _ from 'lodash';
import PropTypes from 'prop-types';
import copyRule from '../../assets/icons/copyRule.svg';
import editRule from '../../assets/icons/editRule.svg';
import openInNew from '../../assets/icons/openInNew.svg';
import exportIcon from '../../assets/icons/export.svg';
import "./manageRule.css";
import { header } from "./data";
import { withRouter } from 'react-router-dom';
import { RoAlert } from 'layouts/RoAlert';
import { NotificationManager } from 'react-notifications';
import * as XLSX from "xlsx";

class ManageRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rules: [],
      ruleDetails: [],
      eligibilityDetails: [],
      chargeDetails: [],
      showCopyModal: false,
      practices: [],
      ruleSearchKey: '',
      ruleSearchValue: '',
      selector: false,
      placeholder: '',
      editRule: false,
      ruleSuccess: '',
      sortData: false,
      selectedColumn: null,
      tabValue: 1,
      activePracticeId: JSON.parse(sessionStorage.getItem('practice') || '{}')?.id,
      copyPracticeId: null,
      ruleToBeCopied: null
    }
  }

  // function to handle tabs
  handleTabs = (event, tabValue) => {
    if (this.state.ruleSearchValue) {
      this.searchRule()
    } else {
      if (!tabValue) {
        this.getRules(tabValue);
      } else {
        this.getRules();
      }
    }
    this.setState({ tabValue });
  };

  // function to sort columns
  onSort = (event, sortKey, type) => {
    const { rules } = this.state;
    if (type === "asc") {
      rules.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      this.setState({
        ...this.state,
        typeState: "desc",
        sortData: !this.state.sortData,
      });
    } else if (type === "desc") {
      rules.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
      this.setState({
        ...this.state,
        typeState: "asc",
        sortData: !this.state.sortData,
      });
    }
    this.setState({ rules });
  };

  // function to change color of heading names when clicked
  colorElement = (index) => {
    if (index === this.state.selectedColumn) {
      this.setState({ selectedColumn: null });
    } else {
      this.setState({ selectedColumn: index });
    }
  }

  // function to handle copy modal
  handleCopyModal = (rule) => {
    this.setState({
      showCopyModal: !this.state.showCopyModal,
      ruleToBeCopied: rule
    });
    this.getPractices();
  }
  closeCopyModal = () => {
    this.setState({ showCopyModal: false })
  }

  onClick = () => {
    this.props.history.push("/rule-dashboard");
  };

  getRules = (practiceType = false) => {
    API.get(
      "getAllRules" + (practiceType == 0 ? "?practiceId=" + practiceType : "")
    )
      .then((response) => {
        let rules = response.data.data;
        this.setState({
          rules: rules ? rules : [],
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // logic to search rules by dropdown selection
  handleSelectChange = (e) => {
    let value = searchValues.filter((item) => {
      return item.key === e.target.value;
    });
    if (this.state.ruleSearchValue !== "") {
      this.getRules();
    }
    this.setState({
      ruleSearchKey: value ? value[0].key : "ruleName",
      selector: false,
      placeholder: value[0].value,
      ruleSearchValue: "",
    });
  };

  handleInputChange = (event) => {
    this.setState({ ruleSearchValue: event.target.value });
    this.searchRule(
      this.state.ruleSearchKey ? this.state.ruleSearchKey : "ruleName",
      event.target.value
    );
  };

  // API to implement rule search
  searchRule = _.debounce(() => {
    this.setState({ rules: [] });
    API.get("searchRule", {
      params: {
        [this.state.ruleSearchKey ? this.state.ruleSearchKey : "ruleName"]:
          this.state.ruleSearchValue,
        practiceId: this.state.tabValue === 0 ? this.state.tabValue : this.state.activePracticeId
      },
    })
      .then((response) => {
        let filteredRules = response.data.data;
        this.setState({ rules: filteredRules });
      })
      .catch((err) => {
        console.error(err);
      });
  }, 1000);

  clickHandler = (e) => {
    this.setState({ selector: !this.state.selector });
  };

  // function to get rule details
  getRuleDetails = (id) => {
    API.get(`getRule/${id}`)
      .then((response) => {
        const data = response.data.ruleDetails[0];
        this.props.history.push({
          pathname: "/rule-dashboard",
          state: {
            id: id,
            data: response.data.ruleDetails,
            chargeDetails: response.data.chargeDetail,
            eligibilityDetails: response.data.eligibilityResponse,
            runRuleDetails: data.runRuleTables,
            ruleStatus: localStorage.setItem("ruleStatus", data.status),
            groupStatus: data.groupStatus,
            ruleSuccess: response.data.success,
          },
        });
        this.setState({
          ruleDetails: data,
          chargeDetails: response.data.chargeDetail,
          eligibilityDetails: data.eligibilityResponse,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // function to get list of all practices
  getPractices = () => {
    API.get("allPractices")
      .then((response) => {
        let practices = response.data.data;
        let practiceList = practices.filter(item => item.id !== this.state.activePracticeId);
        this.setState({ practices: practiceList });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // function to handle edit rule
  handleEdit = (ruleId) => {
    let ruleDetail = this.state.rules.filter((item) => item.ruleId === ruleId);
    let length = ruleDetail[0].ruleInfo.length;
    let obj = {
      for: ruleDetail[0].ruleInfo[length - 1].operator,
      pendValue: ruleDetail[0].result,
      reason: ruleDetail[0].Reason,
      operator: "none",
    };
    ruleDetail[0].ruleInfo.push(obj);
    this.props.history.push({
      pathname: `/edit-rule/${ruleId}`,
      state: {
        editRule: true,
        ruleDetail: ruleDetail[0],
        tags: ruleDetail[0].tags,
        ruleId: ruleId,
        path: `/edit-rule/${ruleId}`,
      },
    });
  };

  // function to copy rule to another practice
  copyRuleToPractice = async () => {
    if (this.state.copyPracticeId === null) {
      return NotificationManager.info('Please select practice!', '', 9000);
    }
    if (this.state.copyPracticeId == this.state.activePracticeId) {
      return NotificationManager.info('This rule is already assign for this practice, Please change practice', '', 9000)
    }
    let body_data = this.state.ruleToBeCopied;
    body_data.practiceId = this.state.copyPracticeId
    this.closeCopyModal()
    const responseFromApi = await API.post('createRule', body_data)
    if (responseFromApi?.status === 200 && responseFromApi.data.success === true) {
      return NotificationManager.success('Rule successfully copied', 'Success', 9000)
    } else {
      return NotificationManager.error('Rule already exist for this practice', 'Failed!', 9000)
    }
  }

  // function to format and export rules
  ruleFormatter = (rules) => {
    let formatedRule = []
    rules.forEach(rule => {
      let tiles = rule.ruleInfo.filter(item => item.procedureTile);
      let newRule = rule
      let pentToReview = rule.result
      delete newRule.result
      delete newRule.ruleType
      delete newRule.practiceId
      delete newRule.groupStatus
      delete newRule.isDelete
      delete newRule.modified_at
      delete newRule.parentRuleId
      if(Object.keys(rule).includes("ruleInfo")){
        let ruleDesc= "When the";
        rule.ruleInfo.forEach((info, idx) => {
          let error = "{{error}}"
          let finalValues, procedureValues;
          let procedureTileValue;
          switch(info?.category){
              case 'Diagnosis 1':
              case 'Diagnosis 2':
              case 'Diagnosis 3':
              case 'Diagnosis 4':
              case 'Diagnosis in any slot':
              case 'Modifier': 
                  procedureTileValue = info.procedureTile === 'any' ? 'for any procedure' : `for procedure tile ${info?.procedureTile}`
                  break;
              default:
                  procedureTileValue = ""
                  break;
          }
          switch(info?.category){
              case 'Diagnosis 1':
              case 'Diagnosis 2':
              case 'Diagnosis 3':
              case 'Diagnosis 4':
              case 'Diagnosis in any slot':
              case 'Procedure Code':
                  finalValues = (info?.condition === 'is' || info?.condition === 'contains') ? 
                  (info?.values?.map(val => val.name ? val.name.split('-')[0].trim() : val))?.join(' or ') : 
                  (info?.condition === 'is missing') ? [] : 
                  (info?.values?.map(val => val.name ? val.name.split('-')[0].trim() : val))?.join(' and ');
                  break;
              default:
                  finalValues = (info?.condition === 'is' || info?.condition === 'contains') ? 
                  (info?.values?.map(val => val.name ? val.name : val))?.join(' or ') : 
                  (info?.condition === 'is missing') ? [] : 
                  (info?.values?.map(val => val.name ? val.name : val))?.join(' and ');
                  break;
          }
          switch(info?.category){
              case 'Global Period':
                  finalValues = (info?.values?.map(val => val.name ? val.name.split('-')[0].trim() : val))?.join(' or ');
                  procedureValues = (info?.procedureArray?.map(val => val.name ? val.name.split('-')[0].trim() : val))?.join(' or ');
                  break;
              default:
                  break;
          }
          switch(info?.category){
              case 'Global Period':
                  ruleDesc = ruleDesc + " " + info.category + " " + "for the procedure(s)" + " " + finalValues + " " + "is" + " " + 
                         info.days + "day(s)" + info.globalDrop + " " + "we cannot bill the procedure(s)" + " " + procedureValues + " "
                  break;
              default:
                  ruleDesc = ruleDesc + " " + (idx > 0 || tiles.length > 0 ? "the " + info?.category + " " + procedureTileValue : info?.category + " " + procedureTileValue) + " " + 
                        info.condition + " " + finalValues + " " + info.operator.toLowerCase() + " "
                  break;
          }

        })
        newRule["ruleInfo"] = ruleDesc + pentToReview;
        newRule["created_at"] = new Date(rule.created_at).toLocaleDateString('en-US');
        newRule["updated_at"] = new Date(rule.updated_at).toLocaleDateString('en-US');
        newRule["status"] = rule.status === true ? 'Active' : 'Not Active'
      }
      formatedRule.push(newRule);
    })
    return formatedRule;
  }

  exportToCsv = () => {
    console.log(sessionStorage.getItem("practice"));
    const exportedExcelName = this.state.tabValue === 0 ? "Multi Practice Rule" : JSON.parse(sessionStorage.getItem("practice")).practiceName;
    let formatedRule = this.ruleFormatter(this.state.rules);
    const worksheet = XLSX.utils.json_to_sheet(formatedRule);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Rule_Details");
    XLSX.writeFile(workbook, `${exportedExcelName}.xlsx`);
  };

  componentDidMount() {
    this.getRules(1);
    localStorage.removeItem("ruleStatus");
    if (this.props.match.params) {
      const { ruleType } = this.props.match.params;
      if (ruleType !== undefined) {
        this.setState({
          tabValue: Number(ruleType),
        });
      }
    }
  }

  render() {
    const { rules, sortData, selectedColumn, tabValue } = this.state;
    let activeRules = rules.filter((item) => item.status === true);
    let inactiveRules = rules.filter((item) => item.status === false);
    const iconStyle = {
      cursor: "pointer",
    };
    const roleDetails = JSON.parse(sessionStorage.getItem('userDetail'));
    return (
      <section>
        <Sidebar
          handleChangePractice={(practice) => {
            if (this.state.ruleSearchValue) {
              this.searchRule()
            } else {
              this.getRules(practice.id);
            }
            this.setState({
              tabValue: 1,
              activePracticeId: practice.id
            });
          }}
        />
        <div
          className="row mb-3"
          style={{
            marginTop: 120,
            marginLeft: 50,
            padding: "0px 50px 0px 40px",
          }}
        >
          <div className="col-lg-12 col-sm-12">
            <Tabs
              value={tabValue}
              variant="fullWidth"
              indicatorColor="primary"
              onChange={this.handleTabs}
            >
              <Tab label="Multi-Practice Rules" />
              <Tab label="Practice Rules" />
            </Tabs>
          </div>
        </div>

        <div className="table-responsive manager">
          <table className="manageTable">
            <thead>
              <tr>
                {header.map((col, index) => (
                  <th key={index} className="text-center font-face-futura">
                    <span
                      style={{
                        color: selectedColumn === index ? "#5CB02C" : "#FFFFFF",
                      }}
                      onClick={(id) => this.colorElement(index)}
                    >
                      <span
                        type="button"
                        style={{ fontWeight: "bold", color: "white" }}
                        onClick={(e) =>
                          sortData
                            ? this.onSort(e, col.key, "desc")
                            : this.onSort(e, col.key, "asc")
                        }
                      >
                        {col.name}
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rules.length > 0 ? (
                rules.map((rule, idx) => (
                  <tr
                    key={idx}
                    style={{ backgroundColor: "white" }}
                    className="table-row"
                  >
                    <td className="text-center py-2 px-0 font-face-futura">
                      <div
                        className="innerdata"
                        style={{
                          borderRadius: "9px 0px 0px 9px",
                          marginLeft: 20,
                        }}
                      >
                        {rule.ruleName}
                      </div>
                    </td>
                    <td className="text-center py-2 px-0 font-face-futura">
                      <div className="innerdata">
                        {new Date(rule.updated_at).toLocaleDateString("en-US")}
                      </div>
                    </td>
                    <td className="text-center py-2 px-0 font-face-futura">
                      <div className="innerdata">{rule.tags.join(", ")}</div>
                    </td>
                    <td className="text-center py-2 px-0 font-face-futura">
                      <div className="innerdata">
                        {rule.status === true ? "On" : "Off"}
                      </div>
                    </td>
                    <td className="text-center py-2 px-0">
                      <div
                        className="innerdata"
                        style={{
                          borderRadius: "0px 9px 9px 0px",
                          marginRight: 20,
                        }}
                      >
                        {/* <img
                          src={editRule}
                          alt="editRule"
                          className="mx-1"
                          style={iconStyle}
                          onClick={(id) => this.handleEdit(rule.ruleId)}
                        />
                        <img
                          src={copyRule}
                          alt="copyRule"
                          className="mx-3"
                          style={iconStyle}
                          onClick={() => this.handleCopyMaodal(rule)}
                        />
                        <img
                          src={openInNew}
                          alt="open"
                          style={iconStyle}
                          onClick={(id) => this.getRuleDetails(rule.ruleId)}
                        /> */}
                        <Tooltip
                          title={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && tabValue === 1) || (roleDetails.roleId === 4 && tabValue === 1)) ? "" : "you don't have permission"}
                          arrow
                        >
                          <IconButton>
                            <input
                              type="image"
                              disabled={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && tabValue === 1) || (roleDetails.roleId === 4 && tabValue === 1)) ? false : true}
                              src={editRule}
                              alt="editRule"
                              className="mx-1"
                              style={iconStyle}
                              onClick={(id) => this.handleEdit(rule.ruleId)}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && tabValue === 1)) ? "" : "you don't have permission"}
                          arrow
                        >
                          <IconButton>
                            <input
                              type="image"
                              disabled={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && tabValue === 1)) ? false : true}
                              src={copyRule}
                              alt="copyRule"
                              className="mx-3"
                              style={iconStyle}
                              onClick={() => this.handleCopyModal(rule)}
                            />
                          </IconButton>
                        </Tooltip>
                        {/**
                         * view rule have all the users access
                         */}
                        <img
                          src={openInNew}
                          alt="open"
                          style={iconStyle}
                          onClick={(id) => this.getRuleDetails(rule.ruleId)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="mt-3 noRecord text-center">
                    No records found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="rule-footer2">
            <div className='foot-flex-exp'>
              <IconButton >
                <div className='export-btn'>
                <input
                  type="image"
                  src={exportIcon}
                  alt="editRule"
                  className="mx-1"
                  style={iconStyle}
                  onClick={() => this.exportToCsv()}
                />
                <span className='export'>Export</span>
                </div>
              </IconButton>
            </div>
            <div className="foot-flex2">
              <div className="foot-right2">
                <span className="font-futura-total">
                  <b>Total Rules: {rules.length}</b>
                </span>
                <br />
                <span className="font-futura-status">
                  Active: {activeRules.length}
                </span>
                <br />
                <span className="font-futura-status">
                  Inactive: {inactiveRules.length}
                </span>
              </div>
            </div>
          </div>
        </div>

        <RoAlert
          show={this.state.showCopyModal}
          handleClose={() => this.setState({ showCopyModal: false })}
          handleSubmit={this.copyRuleToPractice}
          title={"Copy Rule?"}
          type="info"
          content={
            <div className="alert-footer-text text-center">
              Which practice would you like to copy this rule to?<br />
              <select
                onChange={(e) => this.setState({ copyPracticeId: e.target.value })}
                className="p-1 mt-3 copyDrop text-center"
              >
                <option value="">Select Practice</option>
                {this.state.practices.filter(item => item.status).map((practice) => (
                  <option key={practice.id} value={practice.id} >{practice.practiceName}</option>
                ))}
              </select><br />
              <span className='font-face-futura'>This action cannot be undone</span>
            </div>
          }
        />

        <Footer
          selector={this.state.selector}
          clickHandler={this.clickHandler}
          ruleSearchValue={this.state.ruleSearchValue}
          placeholder={this.state.placeholder}
          onSelect={this.handleSelectChange}
          onInput={this.handleInputChange}
        />
      </section>
    );
  }
}
ManageRules.propTypes = {
  history: PropTypes.array.isRequired,
};

export default withRouter(ManageRules);