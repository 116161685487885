import { Grid, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Datatable } from "layouts/Datatable";
import { drawDatatableCell } from "layouts/Datatable/helper";
import { Select } from "layouts/Form/Select";
import { TextInput } from "layouts/Form/TextInput";
import { Panel } from "layouts/Panel";
import { ROButton } from "layouts/RoButton";
import { InsuranceCompanyTableHead, attachState } from "../constants";
import {
  fetchInsurancePayersList,
  linkPayerWithInsurance,
  relinkPayerWithInsurance,
} from "../helper";
import "../style.css";
import { containerStyle, searchTypeOptions } from "./constants";
import { NotificationManager } from "react-notifications";
import { LoadingSpinner } from "lib/loader";
import { RoAlert } from "layouts/RoAlert";

export const TrizettoPayerList = (props) => {
  const {
    handleClose,
    selectedInsurancePayer,
    handleAfterRelinking,
    isNewLinking,
    encounter,
    refreshLinkedPayers,
  } = props;
  const [insurancePayers, setInsurancePayers] = useState([]);
  const [insurancePayersRecord, setInsurancePayersRecord] = useState([]);
  const [selectedTrizettoPayer, setSelectedTrizettoPayer] = useState(
    selectedInsurancePayer
  );
  const [selectedSearchType, setSelectedSearchType] = useState("payerId");
  const [searchInput, setSearchInput] = useState("");
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [showLinkingConfirmation, setShowLinkingConfirmation] = useState(false);

  const handleSelectInsurance = (cell, rowIndex) => {
    const [name, id] = cell;
    const company = insurancePayers.find((i) => {
      if (i.payerName === name.val && i.payerId === id.val) {
        return i;
      }
    });
    const payer = {
      payerName: name.val,
      payerId: id.val,
      id: company.id,
      state: company.state,
    };
    setSelectedTrizettoPayer(payer);
  };

  const isSelectedTrizettoPayer = (payer) => {
    if (
      !selectedTrizettoPayer ||
      !selectedTrizettoPayer.payerName ||
      !selectedTrizettoPayer.payerId
    )
      return false;
    return (
      selectedTrizettoPayer.payerName.trim() === payer.payerName.trim() &&
      selectedTrizettoPayer.payerId === payer.payerId
    );
  };

  const renderSateCol = (states) => {
    if (states === "ALL") return "All States";
    else {
      const stateArr = states ? states.split(",") : [];
      if (stateArr.length < 2) return states;
      else {
        return (
          <Tooltip title={<h6>{states}</h6>}>
            <span className="state-text">{states}</span>
          </Tooltip>
        );
      }
    }
  };

  const restructureRecords = (insurancePayersList) => {
    const records = (insurancePayersList || []).map((insurancePayer, index) => {
      return [
        drawDatatableCell(
          insurancePayer.payerName,
          insurancePayer.payerName,
          "trizetto-table-cell",
          isSelectedTrizettoPayer(insurancePayer),
          "trizetto-table-cell-active"
        ),
        drawDatatableCell(
          insurancePayer.payerId,
          insurancePayer.payerId,
          "trizetto-table-cell",
          isSelectedTrizettoPayer(insurancePayer),
          "trizetto-table-cell-active"
        ),
        drawDatatableCell(
          insurancePayer.state,
          renderSateCol(insurancePayer.state),
          "trizetto-table-cell",
          isSelectedTrizettoPayer(insurancePayer),
          "trizetto-table-cell-active"
        ),
      ];
    });
    setInsurancePayersRecord(records);
  };

  const handleSearchInput = (e) => {
    const input = e.target.value || "";
    setSearchInput(input);
  };

  const handleSelectSearchType = (e) => {
    const val = e.target.value;
    switch (val) {
      case "Trizetto Insurance Company":
        setSelectedSearchType("payerName");
        break;
      case "Trizetto EDI":
        setSelectedSearchType("payerId");
        break;
      case "State":
        setSelectedSearchType("State");
        break;
      default:
        setSelectedSearchType("payerName");
    }
  };

  const handleSearch = async () => {
    if (searchInput === "") fetchAllInsurancePayersList();
    else {
      if (selectedSearchType === "State") {
        setShowTableLoader(true);
        await fetchAllInsurancePayersList();
        const list = insurancePayers.filter((payer) => {
          const payStates = (payer.state || "").toUpperCase();
          const inputState = searchInput.toUpperCase();
          if (payStates.indexOf(inputState) !== -1) {
            return payer;
          }
        });

        await setInsurancePayers(list);
        await restructureRecords(list);
        await setShowTableLoader(false);
        return;
      }

      setShowTableLoader(true);
      const payersList = await fetchInsurancePayersList({
        [selectedSearchType]: searchInput,
      });
      setInsurancePayers(payersList);
      restructureRecords(payersList);
      setShowTableLoader(false);
    }
  };

  const fetchAllInsurancePayersList = async () => {
    setShowTableLoader(true);
    const payersList = await fetchInsurancePayersList();
    setInsurancePayers(payersList);
    restructureRecords(payersList);
    setShowTableLoader(false);
  };

  const handleNewLinking = async () => {
    if (!selectedInsurancePayer) {
      NotificationManager.error("No payer is selected");
      return;
    }

    if (!selectedTrizettoPayer) {
      NotificationManager.error("No insurance company is selected");
      return;
    }

    if (!selectedTrizettoPayer.payerName || !selectedTrizettoPayer.payerId) {
      NotificationManager.error("No insurance company is selected");
      return;
    }
    if (selectedInsurancePayer.linkedPayerId === selectedTrizettoPayer.payerId) {
      NotificationManager.error("Primary Insurance Policy Plan ID cannot be same as Trizetto EDI");
      return;
    }

    const { encounterId, encounterType } = encounter;
    LoadingSpinner.show();
    const insuranceCompany = selectedTrizettoPayer;
    const res = await linkPayerWithInsurance(selectedInsurancePayer.id, {
      ...insuranceCompany,
      linkedPayerId: selectedInsurancePayer.linkedPayerId,
      linkedPayer: selectedInsurancePayer.linkedPayer,
      insuranceType: encounterType,
      encounterId: encounterId,
      payerName:
        insuranceCompany.payerName.trim() +
        " " +
        attachState(insuranceCompany.state),
    });

    if (res) {
      refreshLinkedPayers();
      NotificationManager.success("Linked successfully.");
      handleClose();
      setShowLinkingConfirmation(false);
    }
    LoadingSpinner.hide();
  };

  const handleRelink = async () => {
    if (!selectedInsurancePayer) {
      NotificationManager.error("No payer is selected");
      return;
    }

    if (!selectedTrizettoPayer) {
      NotificationManager.error("No insurance company is selected");
      return;
    }
    LoadingSpinner.show();
    const insuranceCompany = selectedTrizettoPayer;
    const res = await relinkPayerWithInsurance(selectedInsurancePayer.id, {
      ...insuranceCompany,
      linkedPayerId: selectedInsurancePayer.linkedPayerId,
      payerName:
        (selectedInsurancePayer.payerName || "").trim() +
        " " +
        attachState(insuranceCompany.state),
    });

    if (res) {
      handleAfterRelinking(selectedInsurancePayer, insuranceCompany);
      NotificationManager.success("Relinked successfully.");
      handleClose();
      setShowLinkingConfirmation(false);
    }
    LoadingSpinner.hide();
  };

  useEffect(() => {
    fetchAllInsurancePayersList();
  }, []);

  useEffect(() => {
    restructureRecords(insurancePayers);
  }, [selectedTrizettoPayer]);

  const renderSearch = () => {
    return (
      <Panel heading={"Search"} titleSize={18} headStyle={{ height: "50px" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item className="col-5 col-xxl-6">
            <div className="px-3">
              <Select
                options={searchTypeOptions}
                label="Search Type:"
                handleSelect={handleSelectSearchType}
                defaultValue={"Trizetto EDI"}
              />
            </div>
          </Grid>
          <Grid item className="col-5 col-xxl-6">
            <div className="px-3">
              <TextInput
                label="Search:"
                className="w-100"
                onChange={handleSearchInput}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    handleSearch();
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item className="col-2 col-xxl-12">
          <div className="text-right mt-3 mb-2">
          <ROButton type="info-light" className="mx-3" onClick={handleSearch}>
            SEARCH
          </ROButton>
        </div>
          </Grid>
        </Grid>
      
      </Panel>
    );
  };

  const renderTableFooter = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className="pb-2"
        spacing={1}
      >
        <Grid item xs={6}>
          <ROButton
            className="w-100 py-2 px-3 mt-1"
            type="danger"
            onClick={handleClose}
            fontSize={20}
          >
            NEVERMIND
          </ROButton>
        </Grid>
        <Grid item xs={6}>
          <ROButton
            fontSize={20}
            className="w-100  py-2 px-3 mt-1"
            type="info"
            onClick={() => {
              setShowLinkingConfirmation(true);
            }}
          >
            LINK
          </ROButton>
        </Grid>
      </Grid>
    );
  };

  const renderInsuranceCompanies = () => {
    return (
      <Panel
        heading={`Insurance Companies - ${
          selectedInsurancePayer?.linkedPayer || ""
        } ${
          selectedTrizettoPayer.payerName
            ? "(" +
              selectedTrizettoPayer.payerName +
              " - " +
              (selectedTrizettoPayer.payerId ?? "") +
              ")"
            : ""
        }`}
        titleSize={18}
        headStyle={{ height: "40px" }}
        bodyStyle={{ padding: 0 }}


      >
        <br />
        <Datatable           
          head={InsuranceCompanyTableHead}
          records={insurancePayersRecord}
       
          handleRowClick={handleSelectInsurance}
          headerStyle={{ height: "50px" }}
          showLoader={showTableLoader}
          isSortable={true}
          columnStyles={{
            1: {
              whiteSpace: "pre-wrap",
              width: "25px",
            },
            2: {
              whiteSpace: "pre-wrap",
              width: "25px",
            },
          }}
        />
      </Panel>
    );
  };

  return (
    <div style={containerStyle} className="playerlist-datatable">
      <p className="text-center mb-0 font-face-futura text-lead">
        This list associates the payers coming from Kareo with the Trizetto
        clearinghouse
      </p>
      <p className="text-center mb-0 font-face-futura text-lead mb-3">
        You can use your Kareo settings to search from the EDI number that is
        already in your account
      </p>
      {renderSearch()}
      <br />
      {renderInsuranceCompanies()}
      <br />
      {renderTableFooter()}
      <RoAlert
        show={showLinkingConfirmation}
        handleClose={() => setShowLinkingConfirmation(false)}
        handleSubmit={() => {
          isNewLinking ? handleNewLinking() : handleRelink();
        }}
        title="you are linking this payer"
        type="info"
        content={
          <div className="alert-footer-text text-center mt-5">
            you are about to link <strong>{selectedInsurancePayer.linkedPayer}</strong> to{" "}
            <strong>{selectedTrizettoPayer.payerName}</strong>
          </div>
        }
      />
    </div>
  );
};
