/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sidebar from '../../Sidebar';
import {
    Button, Card, CardContent, CardHeader, Tab, Tabs, Switch,
    IconButton, Dialog, DialogTitle, DialogContent, Tooltip
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Loader from 'react-loader-spinner';
import API from '../../../Api';
import { NotificationManager } from 'react-notifications';
import '../settings.css';
import PropTypes from 'prop-types';
import practiceEmailIcon from '../../../assets/icons/practiceEmail.svg';
import practiceCalendar from '../../../assets/icons/practiceCalendar.svg';
import helpIcon from '../../../assets/icons/help.svg';
import settingEdit from '../../../assets/icons/settingEdit.svg';
import settingDelete from '../../../assets/icons/settingDelete.svg';
import cancel from '../../../assets/icons/cancel.svg';
import CheckIcon from '@material-ui/icons/Check';
import showPassword from '../../../assets/icons/showPassword.svg';
import hidePassword from '../../../assets/icons/hidePassword.svg';
import linkStatus from '../../../assets/icons/linkStatus.svg';
import { claimsTime, timeZones } from '../data';
import editAcronym from '../../../assets/icons/settingEdit.svg';
import Swal from 'sweetalert2';
import { TablePagination } from "@material-ui/core";
import { RoCheckbox } from 'layouts/Form/Checkbox';
import { RoAlert } from 'layouts/RoAlert';

export default class PracticeSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            infoValue: 0,
            anchorEl: null,
            type: 'password',
            password: "",
            practiceId: this.props.location.state?.practiceId,
            practiceName: "",
            practiceAcronym: "",
            practiceEmail: "",
            practiceKey: "",
            groupNpi: "",
            autoRunRules: false,
            processClaimsTime: "",
            processClaimsZone: "",
            duplicateClaims: null,
            runEligibilityForPrimary: null,
            runEligibilityForSecondaries: null,
            rejectPrimaryInsurance: null,
            rejectSecondaryInsurance: null,
            allowLinking: null,
            created_at: null,
            practiceStatus: null,
            openModal: false,
            showResult: false,
            showMessage: false,
            patientId: '',
            patientData: {},
            showButton: false,
            loading: false,
            errors: {},
            disableFields: true,
            providerStatus: true,
            providerName: '',
            providerId: '',
            serviceStatus: true,
            serviceLocationName: '',
            providers: [],
            serviceLocations: [],
            disableProviderInfo: true,
            disableServiceInfo: true,
            providerText: 'Edit',
            serviceText: 'Edit',
            selectedProviderIndex: null,
            selectedServiceIndex: null,
            profileButtonText: 'Edit',
            linkPracticeModal: false,
            pmLinkedStatus: false,
            editAcronymModal: false,
            provSelectedStatus: '',
            providersCopy: [],
            page: 0,
            offset: 0,
            totalRows: 0,
            rowsPerPage: 5,
            showPopupWithPermission: false,
            showPopupWithoutPer: false,
            userRole: null,
            /**
             * user button pop-up varibles to update users wrt to practices
             */
            openUsersModal: false,
            userSearchKey: '',
            userList: this.props?.location.state?.users,
            // selectedUserList: this.props?.location.state?.selectedUsers,
            selectedUserId: this.props?.location.state?.practiceData?.userId,
            showAllUsers: false,
            userValues: [],
        }
    }

    // function to handle tab change
    handleTabs = (event, value) => {
        this.setState({ value });
    }

    handleInfoTabs = (event, infoValue) => {
        this.setState({ infoValue });
    }

    // function to handle popover
    handleClick = (event) => {
        this.setState({ anchorEl: event.target });
    }
    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    // function to toggle password visibility
    showHide = (event) => {
        this.setState({ type: this.state.type === 'password' ? 'input' : 'password' });
    }

    // function to handle change
    handleInputChange = (event) => {
        const { errors } = this.state;
        errors[event.target.name] = '';
        this.setState({ [event.target.name]: event.target.value, errors });
    }

    // function to disable/enable input fields
    handleState = () => {
        if (this.state.disableFields === false) {
            this.setState({
                profileButtonText: 'Edit',
                disableFields: true,
                openModal: true
            }, () => {
                // this.updatePractice();
            })
        } else {
            this.setState({
                profileButtonText: 'Save',
                disableFields: false
            })
        }
    }

    // function to handle toggle button
    handleToggle = (event) => {
        this.setState({ [event.target.name]: event.target.checked }, () => {
            this.state.pmLinkedStatus && this.updatePracticeSettings();
        });
    }

    // function to handle process claims time
    handleProcessClaims = (event) => {
        this.setState({ processClaimsTime: event.target.value }, () => {
            this.updatePracticeSettings();
        });
    }
    handleProcessClaimsZone = (event) => {
        this.setState({ processClaimsZone: event.target.value }, () => {
            this.updatePracticeSettings();
        });
    }

    // function to validate password
    isValid = () => {
        const { errors, password, practiceName, practiceAcronym, practiceEmail, groupNpi } = this.state;
        let isValid = true;
        var passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
        if (!practiceName) {
            isValid = false;
            errors.practiceName = "*Please enter practice name";
        }
        if (!groupNpi) {
            isValid = false;
            errors.groupNpi = "*Please enter Group NPI";
        }
        if (!practiceAcronym || practiceAcronym.length > 5) {
            isValid = false;
            errors.practiceAcronym = "*Practice acronym cannot be more than 5 characters";
        }
        if (!practiceEmail || !(/^[+\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(practiceEmail))) {
            isValid = false;
            errors.practiceEmail = "*Please enter a valid email";
        }
        if (!password) {
            isValid = false;
            errors.password = "*Please enter the password";
        }
        else if (password.length < 8 || password.length > 20) {
            isValid = false;
            errors.password = "*Password must be between 8 to 20 characters";
        }
        else if (!password.match(passwordReg)) {
            isValid = false;
            errors.password = "*Password must contain atleast one uppercase, one lowercase, one number and a special character";
        }
        this.setState({ errors });
        return isValid;
    }

    // function to handle acronym modal pop up
    editAcronym = () => {
        this.setState({ editAcronymModal: true })
    }

    // function to handle practice modal
    openPracticeModal = () => {
        if (this.isValid() && !this.state.disableFields) {
            this.setState({ openModal: true });
        }
    }
    closePracticeModal = () => {
        this.setState({ openModal: false, showResult: false, patientId: '', loading: false, showMessage: false, showButton: false });
    }

    // function to validate patient id
    isPatient = () => {
        this.setState({ loading: true });
        const { errors, patientId } = this.state;
        let isPatient = true;
        if (!patientId) {
            isPatient = false;
            errors.patientId = "*Please enter patient Id";
        }
        this.setState({ errors });
        return isPatient;
    }

    getPatient = (id) => {
        if (this.isPatient()) {
            API.post(`kareo/getPatient/${id}`, {
                customerKey: this.state.practiceKey,
                user: this.state.practiceEmail,
                password: this.state.password,
                practiceName: this.state.practiceName
            }).then((response) => {
                const patientData = response.data.result;
                this.setState({
                    patientData: patientData,
                    loading: false
                });
                if (response.data.success === true) {
                    this.setState({ showResult: true, showMessage: false, showButton: true });
                    this.openModal && this.updatePractice();
                } else {
                    NotificationManager.error('Patient not found for the given practice', 'Failed!', 3000);
                    this.setState({ showResult: false, showMessage: true, showButton: false });
                }
            }).catch(err => {
                console.error(err);
            })
        }
    }

    // API to update practice
    updatePractice = () => {
        if (this.isValid() && this.state.disableFields) {
            const data = {
                practiceName: this.state.practiceName,
                practiceAcronym: (this.state.practiceAcronym).toUpperCase(),
                email: this.state.practiceEmail,
                password: this.state.password,
                groupNpi: this.state.groupNpi,
                practiceId: this.state.practiceId
            }
            API.put(`updatePractice/${this.state.practiceId}`, data).then((response) => {
                
                NotificationManager.success('Practice has been updated successfully!', 'Successful!', 3000);
                this.setState({
                    openModal: false,
                    showResult: false,
                    patientId: '',
                    loading: false,
                    showMessage: false,
                    showButton: false,
                    pmLinkedStatus: response.data.data.PMLinkedStatus,
                    linkPracticeModal: false,
                    editAcronymModal: false
                    // disableFields: !this.state.disableFields
                })
                // sessionStorage.setItem('practice', JSON.stringify(response.data.data));
            }).catch(err => {
                NotificationManager.error('An error occured while updating the practice', 'Failed!', 3000);
                console.error(err);
            })
        }
    }

    // API to update practice settings
    updatePracticeSettings = (e) => {
        let { autoRunRules, processClaimsTime, runEligibilityForPrimary, rejectPrimaryInsurance,
            processClaimsZone } = this.state;
        const data = {
            autoRunRules: autoRunRules,
            processClaimsTime: processClaimsTime ? processClaimsTime : '7:00 PM',
            timeZone: processClaimsZone ? processClaimsZone : 'Pacific Standard Time - (GMT-8)',
            runEligibilityForPrimary: runEligibilityForPrimary,
            rejectPrimaryInsuranceWithoutEDILinked: rejectPrimaryInsurance,
        }
        API.put(`updatePractice/${this.state.practiceId}`, data).then((response) => {
            NotificationManager.success('Practice has been updated successfully!', 'Successful!', 3000);
        }).catch(err => {
            NotificationManager.error('An error occured while updating the practice', 'Failed!', 3000);
            console.error(err);
        })
    }

    // function to deactivate practice
    deactivatePractice = () => {
        API.put(`deactivatePractice/${this.state.practiceId}`).then( async (response) => {
            NotificationManager.success('Practice has been '+ response.data.message +' successfully!', 'Successful!', 3000);
            if(response.data.message === 'deactivated') {
                let practice = sessionStorage.getItem('practice');
                if (practice && practice !== 'undefined') {
                    let practiceObj = JSON.parse(practice);
                    if(practiceObj.id === this.state.practiceId) {
                        const res = await API.get("allPractices");
                        const fetchedPractices = res.data?.data || [];
                        let activePractice = fetchedPractices.filter(practice => practice.status)
                        if(activePractice.length) {
                            sessionStorage.setItem("practice", JSON.stringify(activePractice[0]));
                        } else {
                            sessionStorage.removeItem("practice");
                        }
                    }
                }
            }
            this.props.history.push('/settings');
        }).catch(err => {
            console.error(err);
        })
    }

    // function to handle provider input values
    handleInput = (e) => {
        e.persist();
        const { errors } = this.state;
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value, errors });
        errors[name] = '';
    }

    // function to validate providers
    isProviderValid = () => {
        const { errors } = this.state;
        let isProviderValid = true;
        if (!this.state.providerName) {
            isProviderValid = false;
            errors.providerName = '*Please enter provider name';
        }
        if (!this.state.providerId) {
            isProviderValid = false;
            errors.providerId = '*Please enter provider id';
        }
        this.setState({ errors });
        return isProviderValid;
    }

    /**
     * function to add provider
     */
    addProvider = () => {
        let token = JSON.parse(sessionStorage.getItem('token')).token
        if (this.isProviderValid()) {
            const data = {
                practiceId: this.state.practiceId,
                providerName: this.state.providerName,
                providerId: this.state.providerId,
                status: this.state.providerStatus
            }
            API.post('addProvider', data, {
                'headers': {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                if (response.data.success === true) {
                    this.setState({ showPopupWithPermission: false });
                    NotificationManager.success('Provider details added successfully!', 'Successful!', 3000);
                } else {
                    NotificationManager.error('Provider with this name or id already exists!', 'Failure!', 3000);
                    this.setState({ showPopupWithPermission: false });
                }
                this.setState({
                    providerStatus: true,
                    providerName: '',
                    providerId: '',
                    disablePracticeInfo: !this.state.disablePracticeInfo
                });
                this.getProviders();
            }).catch(err => {
                NotificationManager.error('You do not have permission to Add provider!', 'Failure!', 3000);
                console.error(err);
            })
        }
    }

    /**
     * function to reset fields provider
     */
    resetFields = () => {
        if (this.state.infoValue === 0) {
            this.setState({
                providerName: '',
                providerId: ''
            })
        } else if (this.state.infoValue === 1) {
            this.setState({
                serviceLocationName: ''
            })
        }
    }

    /**
     * function to get list of all providers
     */
    getProviders = () => {
        API.post('getAllProviders', {practiceid: this.props.location.state.practiceId}).then((response) => {
            let providers = response.data.data;
            this.setState({ providers: providers ? providers : [] });
        }).catch(err => {
            console.error(err);
        })
    }

    /**
     * function is used to soft delete the providers
     * @param {*} id 
     */
    deleteProvider = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You are deleting the provider.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#006F90",
            cancelButtonColor: "#C77B7B",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                API.delete(`deleteProvider/${id}`).then((response) => {
                    if (response.data.success === true) {
                        NotificationManager.success('Provider deleted successfully!', 'Successful!', 3000);
                        this.getProviders()
                    }
                }).catch(err => {
                    console.error(err);
                })
            }
        })
    }

    /**
     * function to handle change in provider table inputs
     * @param {*} event 
     * @param {*} proId 
     */
    handleProviderInputs = (event, proId) => {
        let providers = [...this.state.providers];
        providers = providers.map((item, i) => {
            if (item.id === proId) {
                if (event.target.name === 'proName') {
                    item.providerName = event.target.value;
                }
                if (event.target.name === 'proId') {
                    item.providerId = event.target.value;
                }
                if (event.target.value === 'active') {
                    item.status = true;
                }
                if (event.target.value === 'inactive') {
                    item.status = false;
                }
            }
            return item;
        })
        this.setState({ [event.target.name]: event.target.value });
        this.setState({ providers });
    }

    /**
     * function to edit provider
     * @param {*} proId 
     */
    editProvider = (proId) => {
        let { providers } = this.state;
        let providerId; let providerName; let providerStatus
        providers = providers.map((item, i) => {
            if (item.id === proId) {
                providerId = item.providerId;
                providerName = item.providerName;
                providerStatus = item.status;
            }
            return item;
        })
        const data = {
            providerId: providerId,
            providerName: providerName,
            providerStatus: providerStatus,
            practiceId: this.props.location.state.practiceId
        }
        API.put(`updateProvider/${proId}`, data).then((response) => {
            if (response.data.success === true) {
                NotificationManager.success('Provider details saved successfully!', 'Successful!', 3000);
            } else {
                NotificationManager.error('Provider with this name or id already exists!', 'Failure!', 3000);
            }
            this.setState({ providers }, () => {
                this.toggleEdit()
            });
            this.getProviders();
        }).catch(err => {
            NotificationManager.error('Provider with this name or id already exists!', 'Failure!', 3000);
            console.error(err);
        })
    }

    handlePopUp = () => {
        let roleData = JSON.parse(sessionStorage.getItem('userDetail'))
        this.setState({ userRole: roleData.roleId })
        if (this.isProviderValid()) {
            roleData.roleId === 1 ? this.setState({ showPopupWithPermission: true }) : this.setState({ showPopupWithoutPer: true })
        }
    }

    // function to validate service
    isServiceValid = () => {
        const { errors } = this.state;
        let isServiceValid = true;
        if (!this.state.serviceLocationName) {
            isServiceValid = false;
            errors.serviceLocationName = '*Please enter service location name';
        }
        this.setState({ errors });
        return isServiceValid;
    }

    // function to add service location
    addServiceLocation = () => {
        if (this.isServiceValid()) {
            const data = {
                practiceId: this.state.practiceId,
                serviceLocationName: this.state.serviceLocationName,
                status: this.state.serviceStatus
            }
            API.post('addServiceLocation', data).then((response) => {
                if (response.data.success === true) {
                    NotificationManager.success('Service location added successfully!', 'Successful!', 3000);
                } else {
                    NotificationManager.error('Service location with this name already exists!', 'Failure!', 3000);
                }
                this.setState({
                    serviceLocationName: '',
                    serviceStatus: true
                });
                this.getServiceLocations();
            }).catch(err => {
                NotificationManager.error('Service location with this name already exists!', 'Failure!', 3000);
                console.error(err);
            })
        }
    }

    // function to get all service locations
    getServiceLocations = () => {
        API.post('getAllServiceLocations', {practiceId: this.props.location.state.practiceId}).then((response) => {
            let serviceLocations = response.data.data;
            this.setState({ serviceLocations: serviceLocations, disablePracticeInfo: !this.state.disablePracticeInfo });
        }).catch(err => {
            console.error(err);
        })
    }

    // function to change service location status
    deleteServiceLocation = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You are deleting the service location.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#006F90",
            cancelButtonColor: "#C77B7B",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                API.delete(`deleteServiceLocation/${id}`).then((response) => {
                    if (response.data) {
                        this.getServiceLocations()
                        NotificationManager.success('Status Changed successfully!', 'Successful!', 3000);
                    }
                }).catch(err => {
                    console.error(err);
                })
            }
        })
    }

    // function to handle change in service table inputs
    handleServiceInputs = (event, serviceId) => {
        let serviceLocations = [...this.state.serviceLocations];
        serviceLocations = serviceLocations.map((item, i) => {
            if (item.id === serviceId) {
                if (event.target.name === 'serviceName') {
                    item.serviceLocationName = event.target.value;
                }
            }
            return item;
        })
        this.setState({ [event.target.name]: event.target.value });
        this.setState({ serviceLocations });
    }

    // function to edit service location
    editServiceLocation = (serviceId) => {
        let { serviceLocations } = this.state;
        let serviceLocationName;
        serviceLocations = serviceLocations.map((item, i) => {
            if (item.id === serviceId) {
                serviceLocationName = item.serviceLocationName
            }
            return item;
        })
        const data = {
            serviceLocationName: serviceLocationName,
            practiceId: this.state.practiceId
        }
        API.put(`updateServiceLocation/${serviceId}`, data).then((response) => {
            if (response.data.success === true) {
                NotificationManager.success('Service location name saved successfully!', 'Successful!', 3000);
            } else {
                NotificationManager.error('Service location with this name already exists!', 'Failed!', 3000);
            }
            this.setState({ serviceLocations }, () => {
                this.toggleEdit();
            });
            this.getServiceLocations();
        }).catch(err => {
            NotificationManager.error('Service location with this name already exists!', 'Failed!', 3000);
            console.error(err);
        })
    }

    toggleEdit = (toggleId) => {
        if (this.state.infoValue === 0) {
            if (toggleId === this.state.selectedProviderIndex) {
                this.setState({
                    selectedProviderIndex: null,
                    disableProviderInfo: true
                })
            } else {
                this.setState({
                    selectedProviderIndex: toggleId,
                    disableProviderInfo: false
                })
            }
        } else if (this.state.infoValue === 1) {
            if (toggleId === this.state.selectedServiceIndex) {
                this.setState({
                    selectedServiceIndex: null,
                    disableServiceInfo: true
                })
            } else {
                this.setState({
                    selectedServiceIndex: toggleId,
                    disableServiceInfo: false
                })
            }
        }
    }

    // function to open link practice modal when PM linked status is false
    openLinkPracticeModal = () => {
        this.setState({ linkPracticeModal: true })
    }
    closeLinkPracticeModal = () => {
        this.setState({ linkPracticeModal: false, showResult: false, patientId: '', loading: false, showMessage: false, showButton: false })
    }

    /**
     * function to handle searching in user button pop-up while add users wrt practices
     * @param {*} value 
     */
    searchValues = (value) => {
        this.setState({ showAllUsers: true })
        const userList = this.props?.location.state?.users;
        let copyOfUser = userList;
        let searchResult = copyOfUser.filter((items) => {
            if ((items.first_name + " " + items.last_name).toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                return items;
            }
        })
        this.setState({ userList: searchResult, userSearchKey: value })
    }

    /**
     * function to handle single select checkbox
     * @param {*} e 
     */
    handleCheckbox = (e) => {
        let checkedValue = e;
        let { selectedUserId } = this.state;
        if (selectedUserId.indexOf(checkedValue) !== -1) {
            let arr = selectedUserId;
            arr.splice(arr.indexOf(checkedValue), 1);
            this.setState({ selectedUserId: arr });
        } else {
            let arr = selectedUserId;
            arr.push(checkedValue);
            this.setState({ selectedUserId: arr })
        }
    }

    // function to handle ok
    handleOk = () => {
        let { userValues, selectedUserId } = this.state;
        selectedUserId.map(item => userValues.push(item));
        this.setState({
            showAllUsers: false,
            userSearchKey: '',
        });
    }

    /**
     * update users with practices 
     */
    updatePracticeUser = () => {
        const prevSelectedUserId = JSON.parse(sessionStorage.getItem('practice'))
        const { selectedUserId, practiceId } = this.state;
        let body = {
            userId: selectedUserId,
            prevUserId: prevSelectedUserId.userId
        }
        API.put(`updatePracticeUser/${practiceId}`, body).then((res) => {
            if (res) {
                NotificationManager.success("User updated successfully with practice!", "Successful!", 3000)
            } else {
                NotificationManager.error("User not updated  with practice!", "Failed!", 3001)
            }
        }).catch((err) => {
            return err;
        })
        this.closeUserModal()
    }

    // function to close users modal pop up
    closeUserModal = () => {
        this.setState({
            openUsersModal: false,
            userSearchKey: '',
            showAllUsers: false,
        })
    }

    // function to handle page change
    handlePageChange = (event, currentPage) => {
        this.setState({
            page: currentPage,
        });
    };

    // function to handle rows per page
    handleRowsPerPage = (event) => {
        let rowspage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage: rowspage,
            page: 0,
        });
    };

    // componentWillUnmount() {
    //     let prePractice = sessionStorage.getItem('prePractice')
    //     sessionStorage.setItem('practice', prePractice)
    //     sessionStorage.removeItem('prePractice')
    // }

    componentDidMount() {
        let practiceId = this.props.location.state.practiceId
        API.get(`getPractice/${practiceId}`)
        .then((response) => {
          const practiceData = response.data.data;
          this.setState({
            password: practiceData?.password,
            practiceId: practiceData?.id,
            practiceName: practiceData?.practiceName,
            practiceAcronym: practiceData?.practiceAcronym,
            practiceEmail: practiceData?.email,
            practiceKey: practiceData?.key,
            groupNpi: practiceData?.groupNPI,
            autoRunRules: practiceData?.autoRunRules,
            processClaimsTime: practiceData?.processClaimsTime,
            processClaimsZone: practiceData?.timeZone,
            duplicateClaims: practiceData?.duplicateClaims,
            runEligibilityForPrimary: practiceData?.runEligibilityForPrimary,
            runEligibilityForSecondaries: practiceData?.runEligibilityForSecondaries,
            rejectPrimaryInsurance: practiceData.rejectPrimaryInsuranceWithoutEDILinked,
            rejectSecondaryInsurance: practiceData.rejectSecondaryInsuranceWithoutEDILinked,
            allowLinking: practiceData.allowLinkingInsuranceWithoutKareoPayerID,
            pmLinkedStatus: practiceData?.PMLinkedStatus,
            selectedUserId: practiceData?.userId,
            practiceStatus: practiceData?.status,
            created_at: practiceData?.created_at
          })
        })
        .catch((err) => {
          console.error(err);
        });
        this.getProviders();
        this.getServiceLocations();
    }

    render() {
        const { state } = this.props.location;
        const roleDetails = JSON.parse(sessionStorage.getItem('userDetail'));
        const { value, infoValue, anchorEl, type, password, errors, openModal, showButton, showMessage, serviceLocations, providers,
            showResult, patientData, patientId, practiceName, practiceAcronym, practiceEmail, groupNpi, disableFields, linkPracticeModal, openUsersModal,
            showAllUsers, userList, selectedUserList, selectedUserId, userSearchKey, pmLinkedStatus, editAcronymModal, page,
            rowsPerPage, showPopupWithPermission, showPopupWithoutPer, userRole } = this.state;

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;
        const style = {
            textAlign: 'left',
            height: 0,
            lineHeight: '16px'
        }
        /**
         * minimum transaction is used to show while adding provider with pop-up message
         */
        let minimum_transaction = JSON.parse(sessionStorage.getItem('clientDetail')).minimum_transaction;
        let defaultChargePerProvider = JSON.parse(sessionStorage.getItem('clientDetail')).defaultChargePerProvider;

        return (
            <section style={{ overflowX: 'hidden' }}>
                <Sidebar defaultDisabledValue={this.state.practiceId} />
                <div className="row" style={{ marginTop: 120, marginLeft: 100 }}>
                    <div className="col-lg-10 col-sm-12 setting-link">
                        <p>
                            <a href="/settings" style={{ textDecoration: 'none', color: '#535353' }}>Settings</a>
                            <NavigateNextIcon style={{ color: '#7BBC9B' }} />Practice Settings
                        </p>
                    </div>
                </div>
                <div className="row">
                    <Card variant="outlined" className="col-lg-12 col-sm-12 info-box">
                        <CardContent className="row" style={{ width: '100%' }}>
                            <div className="col-lg-8 col-sm-10">
                                <div className="row">
                                    <div className="col-sm-4 col-lg-1 mb-2">
                                        <div className='Logo_name '>
                                            <div className='logotext d-flex flex-column align-items-center h-100 justify-content-end'>
                                                <p>{practiceAcronym}</p>
                                                {/* <p>{practiceInitial.toUpperCase()}</p> */}
                                        <Tooltip
                                            title={roleDetails.roleId === 1 ? "" : "you don't have permission"}
                                            arrow
                                        >
                                            <IconButton>
                                                <input
                                                    type="image"
                                                    disabled={roleDetails.roleId === 1 ? false : true}
                                                    src={editAcronym}
                                                    alt="acronym"
                                                    onClick={this.editAcronym}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                            </div>
                                        </div>
                                        {editAcronymModal && <Dialog fullWidth
                                            open={editAcronymModal}
                                            onClose={() => this.setState({ editAcronymModal: false })}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description">
                                            <div align="center" className='planTitle'>
                                                <DialogTitle id="alert-dialog-title">
                                                    <span className='font-face-futura'>{"Practice Acronym"}</span>
                                                </DialogTitle>
                                            </div>
                                            <DialogContent>
                                                <div className='mt-2'>
                                                    <Card className='planCard'>
                                                        <CardHeader className='planCardHead' title={<h5 className="text-white font-face-futura">Acronym</h5>} />
                                                        <CardContent className='planContent'>
                                                            <div className="row">
                                                                <div className='col-lg-12 col-sm-12'>
                                                                    <label htmlFor="practiceName"><strong>Acronym:</strong></label>
                                                                    <input className='practice-input'
                                                                        name="practiceAcronym" maxLength={5}
                                                                        value={practiceAcronym ? (practiceAcronym)?.toUpperCase() : ""}
                                                                        onChange={this.handleInputChange} />
                                                                    <p className="text-danger" style={style}>{errors.practiceAcronym}</p>
                                                                </div>
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                    <footer>
                                                        <div className='actionButton'>
                                                            <Button fullWidth
                                                                className="mt-4 mb-2 mx-2 nevermindBtn"
                                                                variant="outlined" onClick={() => this.setState({ editAcronymModal: false })}>
                                                                <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Nevermind</span>
                                                            </Button>
                                                            <Button fullWidth
                                                                className="mt-4 mb-2 mx-2 linkBtnAfter"
                                                                variant="outlined"
                                                                onClick={() => this.updatePractice()}>
                                                                <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Update</span>
                                                            </Button>
                                                        </div>
                                                    </footer>
                                                </div>
                                            </DialogContent>
                                        </Dialog>}
                                    </div>
                                    <div className="col-sm-8 col-lg-11 mt-2 details_text_sec">
                                        <p>{this.state.practiceName}</p>
                                        <p>
                                            <img src={practiceEmailIcon} alt="practiceEmail" className='practiceEmailIcon' />
                                            <strong>Email:</strong>&nbsp;{this.state.practiceEmail}
                                        </p>
                                        <p>
                                            <img src={practiceCalendar} alt="practiceCalendar" className='practiceCalendar' />&nbsp;
                                            <span className='ptc'><strong>Added:</strong>&nbsp;{new Date(this.state.created_at).toLocaleDateString('en-US')}</span>
                                        </p>
                                        <p>
                                            <img src={linkStatus} alt="linkStatus" className='linkStatus' />
                                            <span className='ptc'>
                                                <strong>Linked Status:</strong>&nbsp;{pmLinkedStatus ? <span>Linked</span> : <span style={{ color: '#954437' }}>Not Linked</span>}<br />
                                                {pmLinkedStatus ? <></> : <span className='resolvePM' onClick={this.openLinkPracticeModal}>Click to resolve</span>}
                                            </span>
                                        </p>
                                    </div>
                                    {linkPracticeModal && <Dialog fullWidth
                                        open={linkPracticeModal}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">
                                        <div align="center" className='planTitle'>
                                            <DialogTitle id="alert-dialog-title">
                                                <span className='font-face-futura'>{"Link Practice"}</span>
                                            </DialogTitle>
                                        </div>
                                        <DialogContent>
                                            <div className='mt-2'>
                                                <Card className='planCard'>
                                                    <CardHeader className='planCardHead' title={<h5 className="text-white font-face-futura">Profile</h5>} />
                                                    <CardContent className='planContent'>
                                                        <div className="row">
                                                            <div className='col-lg-12 col-sm-12'>
                                                                <label htmlFor="practiceName"><strong>Practice Name:</strong></label>
                                                                <input className='practice-input'
                                                                    name="practiceName"
                                                                    value={practiceName}
                                                                    onChange={this.handleInputChange} />
                                                            </div>
                                                            <div className='mt-2 col-lg-12 col-sm-12'>
                                                                <label htmlFor="practiceEmail"><strong>Email:</strong></label>
                                                                <input className='practice-input'
                                                                    name="practiceEmail"
                                                                    value={practiceEmail}
                                                                    onChange={this.handleInputChange} />
                                                            </div>
                                                            <div className='mt-2 row'>
                                                                <div className='col-lg-12 col-sm-12 position-relative'>
                                                                    <label htmlFor="password"><strong>Current Password:</strong></label>
                                                                    <input className='practice-input'
                                                                        type={type}
                                                                        name="password"
                                                                        value={password}
                                                                        onChange={this.handleInputChange} />
                                                                    {/* <a href="javascript:void(0)"
                                                                        style={{ textDecoration: 'none' }}
                                                                    onClick={this.showHide}>{type === 'password' ? 'Reveal' : 'Hide'}</a> */}
                                                                    <img style={{ cursor: 'pointer' }}
                                                                        src={type === 'password' ? hidePassword : showPassword}
                                                                        alt="password" onClick={this.showHide} className="form-btn" />
                                                                </div>

                                                            </div>
                                                            <div className='mt-2 row'>
                                                                <div className='col-lg-12 col-sm-12 position-relative'>
                                                                    <label htmlFor="practiceKey"><strong>Practice Key:</strong></label>
                                                                    <input className={disableFields ? 'disable practice-input' : 'practice-input'}
                                                                        name="practiceKey" disabled value={this.state.practiceKey} />
                                                                    <img src={helpIcon} alt="help" className="form-btn" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                                <Card className="mt-2 planCard">
                                                    <CardHeader className='planCardHead'
                                                        title={<h5 className="text-white font-face-futura">Verify & Add</h5>} />
                                                    <CardContent className='planContent'>
                                                        {showResult ?
                                                            <div className="mb-4">
                                                                <div className="row mt-2">
                                                                    <div className="col-lg-12 col-sm-12">
                                                                        <span><strong>Patient ID:</strong>&nbsp;{patientData?.ID}</span>
                                                                    </div>
                                                                    <div className="col-lg-12 col-sm-12">
                                                                        <span><strong>Patient Name:</strong>&nbsp;{patientData?.PatientFullName}</span>
                                                                    </div>
                                                                    <div className="col-lg-12 col-sm-12">
                                                                        <span><strong>Patient DOB:</strong>&nbsp;{patientData?.DOB}</span>
                                                                    </div>
                                                                </div>
                                                            </div> : showMessage ?
                                                                <div align="center" className="mb-4">
                                                                    <img src={cancel} alt="cancel" />
                                                                    <p className="mt-3 text-center" style={{ fontSize: '50px' }}>There were no results</p>
                                                                </div> :
                                                                <div className='row'>
                                                                    <div className='col-lg-12 col-sm-12'>
                                                                        <input
                                                                            className='practice-input' placeholder="Search with a patient's ID from Kareo..."
                                                                            name="patientId"
                                                                            value={patientId}
                                                                            onChange={this.handleInputChange} />
                                                                        <p className="text-danger" style={{ height: 0, lineHeight: '16px', marginRight: 170 }}>{errors.patientId}</p>
                                                                    </div>
                                                                </div>
                                                        }
                                                        {this.state.loading && patientId ? <Loader className="mb-3 mt-3 text-center" type="TailSpin" color="#10496a" height={50} width={50} /> : showResult ? showResult : showMessage}
                                                        {/* {showMessage &&
                                                            <div className="mb-4">
                                                                <h3 className="mt-3 text-center text-danger">No Records Found!</h3>
                                                            </div>} */}
                                                        <footer>
                                                            {showButton || showMessage ?
                                                                <div align="center" style={{ marginTop: '35px' }}>
                                                                    <p className='showButtonText'>{showResult ? 'Not what you were looking for?' : 'Try searching again...'}</p>
                                                                    <Button fullWidth
                                                                        className="searchAgain"
                                                                        variant="outlined"
                                                                        onClick={this.closePracticeModal}>
                                                                        <span className="text-white font-face-futura">Search Again</span>
                                                                    </Button>
                                                                    {/* <Button
                                                                    className="mx-2 somethingWrong"
                                                                    variant="outlined"
                                                                    onClick={this.closePracticeModal}>
                                                                        <span className="text-white font-face-futura">Something's wrong</span>
                                                                </Button>
                                                                <Button
                                                                    className="mx-2 thatsCorrect"
                                                                    variant="outlined">
                                                                        <span className="text-white font-face-futura">That&#39;s Correct!</span>
                                                                </Button> */}
                                                                </div> :
                                                                <div className='mt-2' align="center">
                                                                    <Button variant='contained' className='verifyLink' onClick={(id) => this.getPatient(patientId)}>
                                                                        <span className="text-white font-face-futura">Verify Link</span>
                                                                    </Button>
                                                                </div>}
                                                        </footer>
                                                    </CardContent>
                                                </Card>
                                                <footer>
                                                    <div className='actionButton'>
                                                        <Button fullWidth
                                                            className="mt-4 mb-2 mx-2 nevermindBtn"
                                                            variant="outlined" onClick={this.closeLinkPracticeModal}>
                                                            <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Nevermind</span>
                                                        </Button>
                                                        <Button fullWidth
                                                            className={showResult ? "mt-4 mb-2 mx-2 linkBtnAfter" : "mt-4 mb-2 mx-2 linkBtnBefore"}
                                                            variant="outlined"
                                                            disabled={showResult ? false : true}
                                                            onClick={() => this.updatePractice()}>
                                                            <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Link Practice</span>
                                                        </Button>
                                                    </div>
                                                </footer>
                                            </div>
                                        </DialogContent>
                                    </Dialog>}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-2 button_deactive ">
                                <Tooltip
                                    title={roleDetails.roleId === 1 ? "" : "you don't have permission"}
                                    arrow
                                >
                                    <span className='font-face-futura'>
                                        <Button
                                            className="text-white deactivateBtn mx-2 mx-sm-0  mx-lg-2 "
                                            disabled={roleDetails.roleId === 1 ? false : true}
                                            variant="contained"
                                            onClick={this.deactivatePractice}>{this.state.practiceStatus ? 'deactivate' : 'activate'}
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip
                                    title={roleDetails.roleId === 1 ? "" : "you don't have permission"}
                                    arrow
                                >
                                    <span className='font-face-futura '>
                                        <Button
                                            className="text-white usersBtn mt-0 mt-sm-2 mt-lg-0 "
                                            disabled={roleDetails.roleId === 1 ? false : true}
                                            variant="contained" onClick={() => this.setState({ openUsersModal: true })}>users
                                        </Button>
                                    </span>
                                </Tooltip>
                            </div>

                            {openUsersModal && <Dialog fullWidth
                                open={openUsersModal}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description">
                                <div align="center" className='planTitle'>
                                    <DialogTitle id="alert-dialog-title">
                                        <span className='font-face-futura'>{"Practice Users"}</span>
                                    </DialogTitle>
                                </div>
                                <DialogContent>
                                    <div className='mt-2'>
                                        <Card className='planCard'>
                                            <CardHeader className='planCardHead' title={<h5 className="text-white font-face-futura">Profile</h5>} />
                                            <CardContent className='planContent'>
                                                <div className="row">
                                                    <div className='col-lg-12 col-sm-12'>
                                                        <label htmlFor="practiceName"><strong>Practice Name:</strong></label>
                                                        <input className='disable practice-input'
                                                            name="practiceName"
                                                            value={practiceName}
                                                            disabled
                                                        // onChange={this.handleInputChange} 
                                                        />
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <Card className="mt-2 planCard pos-relative">
                                            <CardHeader className='planCardHead'
                                                title={<h5 className="text-white font-face-futura">User Access</h5>} />
                                            <CardContent className='editPracticeUser pb-2'>
                                                <input className='p-2 text modifier-searchs'
                                                    type='text'
                                                    value={userSearchKey}
                                                    placeholder='Type something to search...'
                                                    onChange={(e) => {
                                                        this.searchValues(e.target.value)
                                                    }}
                                                /><br />
                                                <div className='drop'>
                                                    {userList?.length > 0 ? (
                                                        userList?.map((option, idx) => (
                                                            <div key={idx} className='optionDrop'>
                                                                <RoCheckbox
                                                                    style={{ width: 18, height: 18 }}
                                                                    value={option.id}
                                                                    checked={selectedUserId?.includes(option.id)}
                                                                    onChange={(e) =>
                                                                        this.handleCheckbox(e.target.value)
                                                                    }
                                                                />
                                                                <span className='spantext'>
                                                                    {option.first_name + " " + option.last_name}
                                                                </span>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <span className="noRecord">No Result found!</span>
                                                    )}
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <footer>
                                            <div className='actionButton'>
                                                <Button fullWidth
                                                    className="mt-2 mb-2 mx-2 nevermindBtn"
                                                    variant="outlined"
                                                    onClick={this.closeUserModal}
                                                >
                                                    <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Nevermind</span>
                                                </Button>
                                                <Button fullWidth
                                                    className="mt-2 mb-2 mx-2 linkBtnAfter"
                                                    variant="outlined"
                                                    onClick={this.updatePracticeUser}
                                                >
                                                    <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Update Users</span>
                                                </Button>
                                            </div>
                                        </footer>
                                    </div>
                                </DialogContent>
                            </Dialog>}

                        </CardContent>
                    </Card>
                </div>
                <div className="row mt-3">
                    <Card className="col-lg-12 col-sm-12 profile-box" variant="outlined">
                        <CardHeader className='profile-box-head' title={<h5 className="m-2 font20 text-white font-face-futura">Profile</h5>} />
                        <CardContent className='card-content-width practice-profile'>
                            <div className='row text-start'>
                                <div className="col-lg-12 col-sm-12">
                                    <label htmlFor="practiceName"><strong>Practice Name:</strong></label>
                                    <input className={disableFields ? 'disable practice-input' : 'practice-input'}
                                        name="practiceName"
                                        value={practiceName}
                                        disabled={disableFields ? 'disabled' : ''}
                                        onChange={this.handleInputChange} />
                                    <p className="text-danger" style={style}>{errors.practiceName}</p>
                                </div>
                            </div>
                            <div className='mt-2 row text-start'>
                                <div className="col-lg-6 col-sm-6">
                                    <label htmlFor="practiceEmail"><strong>Email Address:</strong></label>
                                    <input className={disableFields ? 'disable practice-input' : 'practice-input'}
                                        name="practiceEmail"
                                        value={practiceEmail}
                                        disabled={disableFields ? 'disabled' : ''}
                                        onChange={this.handleInputChange} />
                                    <p className="text-danger" style={style}>{errors.practiceEmail}</p>
                                </div>
                                <div className="col-lg-6 col-sm-6 position-relative">
                                    <label htmlFor="password"><strong>Current Password:</strong></label>
                                    <input className={disableFields ? 'disable practice-input crpass' : 'practice-input'}
                                        type={type}
                                        name="password"
                                        value={password}
                                        disabled={disableFields ? 'disabled' : ''}
                                        onChange={this.handleInputChange} />

                                    <img style={{ cursor: 'pointer' }}
                                        src={type === 'password' ? hidePassword : showPassword}
                                        alt="password" onClick={this.showHide} className="cpass-btn" />

                                    {/* <a href="javascript:void(0)"
                                        style={{ textDecoration: 'none' }}
                                        onClick={this.showHide}>{type === 'password' ? 'Reveal' : 'Hide'}</a> */}
                                    <p className="text-danger" style={style}>{errors.password}</p>
                                </div>
                            </div>
                            <div className='row text-start'>
                                <div className="col-lg-6 col-sm-6">
                                    <label htmlFor="practiceKey"><strong>Practice Key:</strong></label>
                                    <input className='practice-input' style={{ backgroundColor: '#EFEFEF' }}
                                        name="practiceKey" disabled value={this.state.practiceKey} />
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <label htmlFor="groupNpi"><strong>Group NPI:</strong></label>
                                    <input className={disableFields ? 'disable practice-input' : 'practice-input'}
                                        name="groupNpi"
                                        value={groupNpi}
                                        disabled={disableFields ? 'disabled' : ''}
                                        onChange={this.handleInputChange} />
                                    <p className="text-danger" style={style}>{errors.groupNpi}</p>
                                </div>
                            </div>
                            <div className='mt-2 pracButtonDiv'>
                                <Tooltip
                                    title={roleDetails.roleId === 1 ? "" : "you don't have permission"}
                                    // title={(roleDetails.roleId === 1 || roleDetails.roleId === 2) ? "" : "you don't have permission"}
                                >
                                    <span className="text-white font-face-futura">
                                        <Button
                                            variant="contained"
                                            // disabled={(roleDetails.roleId === 1 || roleDetails.roleId === 2) ? false : true}
                                            disabled={roleDetails.roleId === 1 ? false : true}
                                            className={this.state.profileButtonText === 'Edit' ? "m-1 pracEditBtn" : "m-1 saveBtn"}
                                            onClick={this.handleState}>
                                            {this.state.profileButtonText}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </div>
                        </CardContent>
                    </Card>

                    {openModal && <Dialog fullWidth
                        open={openModal}
                        onClose={this.closePracticeModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <div align="center" className='planTitle'>
                            <DialogTitle id="alert-dialog-title">
                                <span className='font-face-futura'>{"Link Practice"}</span>
                            </DialogTitle>
                        </div>
                        <DialogContent>
                            <div className='mt-2'>
                                <Card className="mt-2 planCard">
                                    <CardHeader className='planCardHead'
                                        title={<h5 className="text-white font-face-futura">Verify & Add</h5>} />
                                    <CardContent className='planContent'>
                                        {showResult ?
                                            <div className="mb-4">
                                                <div className="row mt-2">
                                                    <div className="col-lg-12 col-sm-12">
                                                        <span><strong>Patient ID:</strong>&nbsp;{patientData?.ID}</span>
                                                    </div>
                                                    <div className="col-lg-12 col-sm-12">
                                                        <span><strong>Patient Name:</strong>&nbsp;{patientData?.PatientFullName}</span>
                                                    </div>
                                                    <div className="col-lg-12 col-sm-12">
                                                        <span><strong>Patient DOB:</strong>&nbsp;{patientData?.DOB}</span>
                                                    </div>
                                                </div>
                                            </div> : showMessage ?
                                                <div align="center" className="mb-4">
                                                    <img src={cancel} alt="cancel" />
                                                    <p className="mt-3 text-center" style={{ fontSize: '50px' }}>There were no results</p>
                                                </div> :
                                                <div className='row'>
                                                    <div className='col-lg-12 col-sm-12'>
                                                        <input
                                                            className='practice-input' placeholder="Search with a patient's ID from Kareo..."
                                                            name="patientId"
                                                            value={patientId}
                                                            onChange={this.handleInputChange} />
                                                        <p className="text-danger" style={{ height: 0, lineHeight: '16px', marginRight: 170 }}>{errors.patientId}</p>
                                                    </div>
                                                </div>
                                        }
                                        {this.state.loading && patientId ? <Loader className="mb-3 mt-3 text-center" type="TailSpin" color="#10496a" height={50} width={50} /> : showResult ? showResult : showMessage}
                                        <footer>
                                            {showButton || showMessage ?
                                                <div align="center" style={{ marginTop: '35px' }}>
                                                    <p className='showButtonText'>{showResult ? 'Not what you were looking for?' : 'Try searching again...'}</p>
                                                    <Button fullWidth
                                                        className="searchAgain"
                                                        variant="outlined"
                                                        onClick={this.closePracticeModal}>
                                                        <span className="text-white font-face-futura">Search Again</span>
                                                    </Button>
                                                </div> :
                                                <div className='mt-2' align="center">
                                                    <Button variant='contained' className='verifyLink' onClick={(id) => this.getPatient(patientId)}>
                                                        <span className="text-white font-face-futura">Verify Link</span>
                                                    </Button>
                                                </div>}
                                        </footer>
                                    </CardContent>
                                </Card>
                                <footer>
                                    <div className='actionButton'>
                                        <Button fullWidth
                                            className="mt-4 mb-2 mx-2 nevermindBtn"
                                            variant="outlined" onClick={this.closePracticeModal}>
                                            <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Nevermind</span>
                                        </Button>
                                        <Button fullWidth
                                            className={showResult ? "mt-4 mb-2 mx-2 linkBtnAfter" : "mt-4 mb-2 mx-2 linkBtnBefore"}
                                            variant="outlined"
                                            disabled={showResult ? false : true}
                                            onClick={() => this.updatePractice()}>
                                            <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Link Practice</span>
                                        </Button>
                                    </div>
                                </footer>
                            </div>
                        </DialogContent>
                    </Dialog>}

                    <Card className="col-lg-12 col-sm-12 practice-box mt-3" variant="outlined">
                        <CardHeader className='profile-box-head' title={<h5 className="mt-2 font20 text-white font-face-futura">Practice Settings</h5>} />
                        <CardContent className="card-content-width">
                            <Tabs
                                value={value}
                                onChange={this.handleTabs}
                                variant="fullWidth"
                                indicatorColor="primary">
                                <Tab label="Rules" className='font20' />
                                <Tab label="Eligibility" className='font20' />
                                <Tab label="Additional" className='font20' />
                            </Tabs>
                            {value === 0 &&
                                <div style={{ pointerEvents: (roleDetails.roleId === 1 || roleDetails.roleId === 2 || roleDetails.roleId === 3) ? "auto" : "none" }} 
                                align="center" className="mb-3 row rules-box">
                                    <div className='col-lg-12 col-sm-12'>
                                        <table className='table-borderless rules-wdt'>
                                            <tr>
                                                <td><span>Auto-Run Rules:</span></td>
                                                <td>
                                                    <span className='font12 custom_switch'>Off
                                                        <Switch
                                                            checked={this.state.autoRunRules && pmLinkedStatus === true}
                                                            name="autoRunRules"
                                                            onChange={this.handleToggle}
                                                        // onClick={ this.state.pmLinkedStatus === "true" && this.updatePracticeSettings} 
                                                        />On
                                                    </span>
                                                </td>
                                            </tr><br />
                                            <tr>
                                                <td><span>Process claims at what time?</span></td>
                                                <td>
                                                    <select className='prc_select' name="processClaimsTime"
                                                        value={this.state.processClaimsTime}
                                                        // onChange={this.updatePracticeSettings}
                                                        onChange={this.handleProcessClaims}
                                                    >
                                                        {claimsTime.map((time, idx) => (
                                                            <option key={idx} value={time.value}>{time.key}</option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className='prc_select2' name="processClaimsZone"
                                                        value={this.state.processClaimsZone}
                                                        // onChange={this.updatePracticeSettings}
                                                        onChange={this.handleProcessClaimsZone}
                                                    >
                                                        <option>Select</option>
                                                        {timeZones.map((time, idx) => (
                                                            <option key={idx} value={time.value}>{time.key}</option>
                                                        ))}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <small>We will automatically download the claims an hour before the scheduled time</small>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            }
                            {value === 1 &&
                                <div style={{ pointerEvents: (roleDetails.roleId === 1 || roleDetails.roleId === 2 || roleDetails.roleId === 3) ? "auto" : "none" }} 
                                align="center" className="mb-3 row rules-box1">
                                    <div className='col-lg-12 col-sm-12'>
                                        <table className='table-borderless wdt'>
                                            <tr>
                                                <td><span>Run elegibility for primaries:</span></td>
                                                <td>
                                                    <span className='font12 custom_switch'>Off
                                                        <Switch
                                                            checked={this.state.runEligibilityForPrimary}
                                                            name="runEligibilityForPrimary"
                                                            onChange={this.handleToggle} onClick={this.updatePracticeSettings} />On
                                                    </span>
                                                </td>
                                            </tr><br />
                                            <tr>
                                                <td><span>Reject primary insurances without EDI Linked:</span></td>
                                                <td>
                                                    <span className='font12 custom_switch'>Off
                                                        <Switch
                                                            checked={this.state.rejectPrimaryInsurance}
                                                            name="rejectPrimaryInsurance"
                                                            onChange={this.handleToggle} onClick={this.updatePracticeSettings} />On
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    {/* <p style={{ fontSize: 24 }}>Run elegibility for secondaries:
                                        <span style={{ marginLeft: 235 }}>
                                            Off<Switch
                                                color="primary"
                                                style={{ color: '#10496a' }}
                                                checked={this.state.runEligibilityForSecondaries}
                                                name="runEligibilityForSecondaries"
                                                onChange={this.handleToggle} />On
                                        </span>
                                    </p> */}
                                    {/* <p style={{ fontSize: 24 }}>Reject secondary insurances without EDI Linked:
                                        <span style={{ marginLeft: 40 }}>
                                            Off<Switch
                                                color="primary"
                                                style={{ color: '#10496a' }}
                                                checked={this.state.rejectSecondaryInsurance}
                                                name="rejectSecondaryInsurance"
                                                onChange={this.handleToggle} />On
                                        </span>
                                    </p> */}
                                    {/* <p style={{ fontSize: 24 }}>Allow linking insurance without Kareo Payer ID:
                                        <span style={{ marginLeft: 60 }}>
                                            Off<Switch
                                                color="primary"
                                                style={{ color: '#10496a' }}
                                                checked={this.state.allowLinking}
                                                name="allowLinking"
                                                onChange={this.handleToggle} />On
                                        </span>
                                    </p> */}
                                </div>
                            }
                            {/* <div className='pracSettingDiv'>
                                <Button
                                variant="contained"
                                className="m-1 saveBtn"
                                onClick={this.updatePracticeSettings}>
                                    <span className="text-white font-face-futura">Save</span>
                                </Button>
                            </div> */}
                        </CardContent>
                    </Card>

                    <Card className="col-lg-12 col-sm-12 mt-3 mb-4 practice-info-box" variant="outlined">
                        <CardHeader className='profile-box-head' title={<h5 className="mt-2 font20 text-white font-face-futura">Practice Information</h5>} />
                        <CardContent className="card-content-width">
                            <Tabs
                                value={infoValue}
                                onChange={this.handleInfoTabs}
                                variant="fullWidth"
                                indicatorColor="primary">
                                <Tab label="Providers" className='font20' />
                                <Tab label="Service Locations" className='font20' />
                            </Tabs>
                            {infoValue === 0 &&
                                <>
                                    <div className="row mt-5 mb-3 text-start">
                                        <div className='col-lg-5 col-sm-5'>
                                            <label htmlFor='providerName' className='providerLabel'>Provider Name:</label><br />
                                            <input
                                                className='providerInput' 
                                                disabled={defaultChargePerProvider === true ? roleDetails.roleId === 1 ? false : true :
                                                    defaultChargePerProvider === false ? false : true}
                                                name='providerName' value={this.state.providerName}
                                                onChange={this.handleInput} />
                                            <p className="text-danger" style={style}>{errors.providerName}</p>
                                        </div>
                                        <div className='col-lg-5 col-sm-5'>
                                            <label htmlFor='providerId' className='providerLabel'>Provider ID:</label><br />
                                            <input
                                                className='providerInput' 
                                                disabled={defaultChargePerProvider === true ? roleDetails.roleId === 1 ? false : true :
                                                    defaultChargePerProvider === false ? false : true}
                                                name='providerId' value={this.state.providerId}
                                                onChange={this.handleInput} />
                                            <img className='mxTo' src={helpIcon} alt="help" style={{ cursor: 'pointer' }} />
                                            <p className="text-danger" style={style}>{errors.providerId}</p>
                                        </div>
                                        <div className='mt-3 col-lg-2 col-sm-2 pracButtonDiv px5'>
                                            <Button
                                                variant="contained" 
                                                disabled={defaultChargePerProvider === true ? roleDetails.roleId === 1 ? false : true :
                                                    defaultChargePerProvider === false ? false : true}
                                                className="m-1 pracEditBtn"
                                                onClick={this.resetFields}>
                                                <span className="text-white font-face-futura">Reset</span>
                                            </Button>
                                            <Button
                                                variant="contained" 
                                                disabled={defaultChargePerProvider === true ? roleDetails.roleId === 1 ? false : true :
                                                    defaultChargePerProvider === false ? false : true}
                                                className="m-1 saveBtn"
                                                onClick={this.handlePopUp}>
                                                <span className="text-white font-face-futura">Add</span>
                                            </Button>
                                        </div>
                                    </div>

                                    <div className='table-responsive font-face-futura setting-table'>
                                        <table className='table practiceTable'>
                                            <thead>
                                                <tr>
                                                    <th>Provider Name</th>
                                                    <th>Provider ID</th>
                                                    {defaultChargePerProvider === true && <th>Status</th>}
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {providers && providers
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((prov, idx) => (
                                                        <tr key={idx}>
                                                            <td>
                                                                <input
                                                                    type='text'
                                                                    name="proName"
                                                                    className={this.state.selectedProviderIndex === prov.id ? 'providerName text-center' : 'disable providerName text-center'}
                                                                    value={prov.providerName}
                                                                    disabled={this.state.selectedProviderIndex === prov.id ? false : true}
                                                                    onChange={(e) => this.handleProviderInputs(e, prov.id)} />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type='text'
                                                                    name="proId"
                                                                    className={this.state.selectedProviderIndex === prov.id ? 'providerId text-center' : 'disable providerId text-center '}
                                                                    value={prov.providerId}
                                                                    disabled={this.state.selectedProviderIndex === prov.id ? false : true}
                                                                    onChange={(e) => this.handleProviderInputs(e, prov.id)} />
                                                            </td>
                                                            {defaultChargePerProvider === true &&
                                                            <td>
                                                                <select
                                                                    className={(roleDetails.roleId === 1 && this.state.selectedProviderIndex === prov.id) ? 'providerId text-center' : 'disable providerId text-center p-0'}
                                                                    onChange={(e) => this.handleProviderInputs(e, prov.id)}
                                                                    disabled={((roleDetails.roleId === 1 || roleDetails.roleId === 2 || roleDetails.roleId === 3 || roleDetails.roleId === 4) && this.state.selectedProviderIndex === prov.id) ? false : true}
                                                                    value={prov.status === true ? "active" : prov.status === false ? "inactive" : ''}
                                                                >
                                                                    <option value="active">Active</option>
                                                                    <option value="inactive">Not Active</option>
                                                                </select>
                                                            </td>}
                                                            <td>
                                                                {this.state.selectedProviderIndex === prov.id ?
                                                                    <IconButton>
                                                                        <CheckIcon style={{ color: '#7BBC9B' }} onClick={() => this.editProvider(prov.id)} />
                                                                    </IconButton> :
                                                                    <Tooltip
                                                                        title={defaultChargePerProvider === true ? 
                                                                            (roleDetails.roleId === 1 || roleDetails.roleId === 2 || roleDetails.roleId === 3 || roleDetails.roleId === 4) ? "" : "you don't have permission" : 
                                                                            defaultChargePerProvider === false ? "" : "you don't have permission"}
                                                                        arrow
                                                                    >
                                                                        <IconButton>
                                                                            <input type="image" className='mx-1' alt={1} 
                                                                            src={settingEdit} 
                                                                            disabled={defaultChargePerProvider === true ? 
                                                                                (roleDetails.roleId === 1 || roleDetails.roleId === 2 || roleDetails.roleId === 3 || roleDetails.roleId === 4) ? false : true : 
                                                                                defaultChargePerProvider === false ? false : true} 
                                                                            onClick={() => this.toggleEdit(prov.id)} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                                <Tooltip
                                                                    title={defaultChargePerProvider === true ? 
                                                                        (roleDetails.roleId === 1 || roleDetails.roleId === 2 || roleDetails.roleId === 3 || roleDetails.roleId === 4) ? "" : "you don't have permission" : 
                                                                        defaultChargePerProvider === false ? "" : "you don't have permission"}
                                                                    arrow
                                                                >
                                                                    <IconButton>
                                                                        <input type="image" 
                                                                            disabled={defaultChargePerProvider === true ? 
                                                                                (roleDetails.roleId === 1 || roleDetails.roleId === 2 || roleDetails.roleId === 3 || roleDetails.roleId === 4) ? false : true : 
                                                                                defaultChargePerProvider === false ? false : true}
                                                                        className='mx-1' src={settingDelete} alt={1} onClick={() => this.deleteProvider(prov.id)} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 20]}
                                            component="div"
                                            count={providers.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                                            onPageChange={this.handlePageChange}
                                            onRowsPerPageChange={this.handleRowsPerPage}
                                        />
                                    </div>
                                    <RoAlert
                                        showproviderPopup={showPopupWithoutPer}
                                        show={userRole === 1 ? showPopupWithPermission : showPopupWithoutPer}
                                        handleClose={() =>
                                            this.setState({ showPopupWithPermission: false, showPopupWithoutPer: false })
                                        }
                                        handleSubmit={() => this.addProvider()}
                                        title={userRole === 1 ? "Add Provider" : "UH, OH!"}
                                        type={userRole === 1 ? "info" : "danger"}
                                        content={
                                            <div className="alert-footer-text text-center">
                                                {userRole === 1 ? `Adding a provider will add ${minimum_transaction} to your pool of transactions.` :
                                                    "Adding a provider will change your invoice structure"}
                                                <br />
                                                {userRole === 1 ? "" : "Only System Admins add providers"}
                                            </div>
                                        }
                                    />
                                </>
                            }
                            {infoValue === 1 &&
                                <>
                                    <div className="row mt-5 mb-3 text-start">
                                        <div className='col-lg-10 col-sm-10'>
                                            <label htmlFor='serviceLocationName' className='providerLabel'>Service Location Name:</label><br />
                                            <input className='providerInput'
                                                type='text' disabled={roleDetails.roleId === 5 ? true : false}
                                                name='serviceLocationName' value={this.state.serviceLocationName}
                                                onChange={this.handleInput} />
                                            <img className='mx-2' src={helpIcon} alt="help" style={{ cursor: 'pointer' }} />
                                            <p className="text-danger" style={style}>{errors.serviceLocationName}</p>
                                        </div>
                                        <div className='mt-3 col-lg-2 col-sm-2 pracButtonDiv px5'>
                                            <Button
                                                variant="contained" disabled={roleDetails.roleId === 5 ? true : false}
                                                className="m-1 pracEditBtn"
                                                onClick={this.resetFields}>
                                                <span className="text-white font-face-futura">Reset</span>
                                            </Button>
                                            <Button
                                                variant="contained" disabled={roleDetails.roleId === 5 ? true : false}
                                                className="m-1 saveBtn"
                                                onClick={this.addServiceLocation}>
                                                <span className="text-white font-face-futura">Add</span>
                                            </Button>
                                        </div>
                                    </div>

                                    <div className='table-responsive font-face-futura setting-table'>
                                        <table className='table serviceTable'>
                                            <thead>
                                                <tr>
                                                    <th>Service Location Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {serviceLocations && serviceLocations
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((serv, idx) => (
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type='text'
                                                                    name='serviceName'
                                                                    className={this.state.selectedServiceIndex === serv.id ? 'providerName text-center' : 'disable providerName text-center'}
                                                                    value={serv.serviceLocationName}
                                                                    disabled={this.state.selectedServiceIndex === serv.id ? false : true}
                                                                    onChange={(e) => this.handleServiceInputs(e, serv.id)} />
                                                            </td>
                                                            <td>
                                                                {this.state.selectedServiceIndex === serv.id ?
                                                                    <IconButton>
                                                                        <CheckIcon style={{ color: '#7BBC9B' }} onClick={() => this.editServiceLocation(serv.id)} />
                                                                    </IconButton> :
                                                                    // <Tooltip
                                                                    //     title={(roleDetails.roleId === 1 || roleDetails.roleId === 3 || roleDetails.roleId === 4) ? "" : "you don't have permission"}
                                                                    //     arrow
                                                                    // >
                                                                        <IconButton>
                                                                            <input type="image" className='mx-1' alt={1} 
                                                                                src={settingEdit} 
                                                                                // disabled={(roleDetails.roleId === 1 || roleDetails.roleId === 3 || roleDetails.roleId === 4) ? false : true} 
                                                                                onClick={() => this.toggleEdit(serv.id)} />
                                                                        </IconButton>
                                                                    // </Tooltip>
                                                                    }
                                                                {/* <Tooltip
                                                                    title={(roleDetails.roleId === 1 || roleDetails.roleId === 3 || roleDetails.roleId === 4) ? "" : "you don't have permission"}
                                                                    arrow
                                                                > */}
                                                                    <IconButton>
                                                                        <input
                                                                            // disabled={(roleDetails.roleId === 1 || roleDetails.roleId === 3 || roleDetails.roleId === 4) ? false : true}
                                                                            type="image"
                                                                            className='mx-1'
                                                                            alt=""
                                                                            src={settingDelete}
                                                                            onClick={() => this.deleteServiceLocation(serv.id)} />
                                                                    </IconButton>
                                                                {/* </Tooltip> */}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 20]}
                                            component="div"
                                            count={serviceLocations.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                                            onPageChange={this.handlePageChange}
                                            onRowsPerPageChange={this.handleRowsPerPage}
                                        />
                                    </div>
                                </>
                            }
                        </CardContent>
                    </Card>
                </div>
            </section>
        )
    }
}
PracticeSettings.propTypes = {
    location: PropTypes.string.isRequired,
    history: PropTypes.array.isRequired
}