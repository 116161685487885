import axios from 'axios';
import jwt_decode from 'jwt-decode';

const baseUrls = {
    "http://localhost:3001": "http://localhost:3000/",
    "https://dev.ruleout.com": "https://backenddev.ruleout.com/",
    "https://app.ruleout.com": "https://backendapp.ruleout.com/",
    "https://dev-admin.ruleout.com/admin-panel": "https://backenddev.ruleout.com/"
}

const originUrl = window.location.origin

const API = axios.create({
    baseURL: baseUrls[originUrl],
    // baseURL: 'http://localhost:3000/',
    // baseURL: 'https://backendapp.ruleout.com/',
    // baseURL: 'https://ruleout-hltg.chetu.com/',
    // baseURL: "https://backenddev.ruleout.com/",
    // baseURL: "https://dev1backend.ruleout.com/",
    _headers: {
        // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        // 'Authorization': localStorage.getItem('accessToken') ? `Bearer ${localStorage.getItem('accessToken')}` : null,
        'Content-Type': 'application/json;charset=UTF-8'
    },
    get headers() {
        return this._headers;
    },
    set headers(value) {
        this._headers = value;
    },
    responseType: 'json'
});


const getPracticeId = () => {
    const practice = sessionStorage.getItem('practice');
    if(practice) {
        const parsed = JSON.parse(practice);
        if(parsed === 'all'){
            return '0'
        }
        if(parsed && parsed.id) return parsed.id
        return parsed;
    }
}

const getAuthToken = () => {
    const token = sessionStorage.getItem("token");
    if (token) {
      const parsed ='Bearer ' + JSON.parse(token).token;
      if (!!parsed) {
        return parsed;
      }
    }
  }

const getClientKey = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const token = jwt_decode(sessionStorage.getItem('token'));
            resolve(token.clientKey)
        }, 100);
    })
}

API.interceptors.request.use(
    async config => {
        config.headers['practiceid'] = await getPracticeId()
        if (sessionStorage.getItem('token')) {
            config.headers['Authorization'] = getAuthToken();
            config.headers['client-id'] = await getClientKey()
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


export default API;