import { SortedState } from "layouts/Datatable/DatatableHead/constants";

export const LinkedPayersTableHead = [
  { name: "", isSortable: false, sortedState: SortedState.NO_SORT },
  {
    name: "Insurance Name",
    isSortable: true,
    sortedState: SortedState.NO_SORT,
  },
  { name: "Payer ID", isSortable: true, sortedState: SortedState.NO_SORT },
  {
    name: "Trizetto Insurance Company",
    isSortable: true,
    sortedState: SortedState.ASC,
  },
  { name: "Trizetto EDI", isSortable: true, sortedState: SortedState.NO_SORT },
  { name: "State", isSortable: true, sortedState: SortedState.NO_SORT },
  { name: "Status", isSortable: true, sortedState: SortedState.NO_SORT },
];

export const InsuranceCompanyTableHead = [
  {
    name: "Trizetto Insurance Company",
    isSortable: true,
    sortedState: SortedState.NO_SORT,
  },
  { name: "Trizetto EDI", isSortable: true, sortedState: SortedState.NO_SORT },
  { name: "State", isSortable: true, sortedState: SortedState.NO_SORT },
];


export const LinkedPayerStatus = {
  APPROVED: 'Approved',
  DISAPPROVED: 'Disapproved'
}

export const attachState = (state) => `Servicing States:${state}`