import React, { Component } from 'react';
import { Box, Paper } from '@material-ui/core';
import SideBar from '../Sidebar';
import Footer from '../Footer/Footer';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Button } from '@material-ui/core';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { withRouter } from 'react-router-dom';
import API from '../../Api';
import { IconButton } from '@mui/material';
import moment from 'moment';
import { searchItems } from '../Footer/data';
import _ from 'lodash';
import { columnHeader } from './data';
import "./style.css"

class RunRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRule: false,
            getLastRule: [],
            chargeData: [],
            encounterData: this.props.location.state.encounterData,
            trizettoData: this.props.location.state.trizettoData,
            rulesRan: this.props.location.state.rulesRan,
            rulesDate: this.props.location.state.rulesDate,
            // formattedRulesDate: new Date(this.props.location.state.rulesDate),
            // rulesDate: this.props.location.state.rulesDate,
            rulesData: [],
            trizettoResponse: [],
            ruleSuccess: '',
            trizettoSuccess: this.props.location.state.trizettoSuccess,
            searchKey: '',
            searchValue: '',
            selectedColumn: null,
            sortData: false,
            eligibilityData: []
        }
    }

    // function to sort columns
    onSort = (event, sortKey, type) => {
        const {rulesData} = this.state;
        if(type === 'asc'){
            rulesData.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
            this.setState({
                ...this.state,
                typeState: 'desc',
                sortData: !this.state.sortData
        });
        }else if(type === 'desc'){
            rulesData.sort((a,b) => b[sortKey].localeCompare(a[sortKey]))
            this.setState({
                ...this.state,
                typeState: 'asc',
                sortData: !this.state.sortData
        });
        }
        this.setState({rulesData});
    }

    // function to change color of heading names when clicked
    colorElement = (index) => {
        if(index === this.state.selectedColumn){
            this.setState({selectedColumn: null});
        }else{
            this.setState({selectedColumn: index});
        }
    }

    // logic to search encounters by dropdown selection
    handleSelectChange = e => {
        // console.log(e.target.value);
        let value = searchItems.filter(item => {
            return item.key === e.target.value
        })
        // console.log(value);
        // console.log(value[0].key);
        this.setState({ searchKey: value[0].key, searchValue: '' });
    }

    handleInputChange = (event) => {
        this.setState({ searchValue: event.target.value });
        this.searchEncounters(this.state.searchKey, event.target.value);
    }

    // API to implement search
    searchEncounters = _.debounce(() => {
        this.setState({ rulesData: [] });
        // console.log(this.state.searchKey);
        API.get('searchRunEncounter', { params: { [this.state.searchKey]: this.state.searchValue } }).then((response) => {
            // console.log(response.data);
            let filteredEncounters = response.data.data;
            this.setState({ rulesData: filteredEncounters });
        }).catch(err => {
            console.error(err);
        })
    }, 1000);

    // function to handle date change
    handleDateChange = (event) => {
        const formattedDate = moment(event).format('YYYY-MM-DD');
        this.setState({ rulesDate: event });
        this.getRunRules(formattedDate);
    }
    // handleDateChange = (event) => {
    //     console.log(this.state.rulesDate);
    //     this.setState({rulesDate: event.target.value});
    //     this.getRunRules(event.target.value);
    // }

    // function to get run rules
    // getRunRules = (date) => {
    //     // console.log("DATA", date)
    //     this.setState({ rulesData: [], trizettoData: [], encounterData: [] });
    //     API.get('getRunRules', { params: { date: date } }).then((response) => {
    //         // console.log(response.data);
    //         let filteredData = response.data;
    //         let rulesData = filteredData.chargeDetail;
    //         let trizettoData = filteredData.eligibilityDetail;
    //         trizettoData.sort(function (a, b) {
    //             return new Date(b.currentTime) - new Date(a.currentTime);
    //         })
    //         let filteredIds = [];
    //         trizettoData.forEach(el => {
    //             filteredIds.push(el.instanceID);
    //             // console.log(filteredIds)
    //         });
    //         let res = [];
    //         filteredIds.forEach((el) => {
    //             res.push(rulesData.find(el2 => el2.instanceID === el))
    //         })
    //         const sortedEncounters = res.filter(item => item);
    //         // console.log("filteredData", sortedEncounters, trizettoData);
    //         this.setState({
    //             rulesData: sortedEncounters ? sortedEncounters : [],
    //             trizettoData: trizettoData,
    //             ruleSuccess: response.data.success
    //         });
    //     }).catch(err => {
    //         console.error(err);
    //     })
    // }
    getRunRules = (date) => {
        // console.log("DATA", this.state.rulesDate)
        this.setState({ rulesData: [], trizettoData: [], encounterData: [], eligibilityData: [] });
        API.get('getRunRules', { params: { date: date } }).then((response) => {
            // console.log(response.data);
            let filteredData = response.data;
            let rulesData = filteredData.chargeDetail;
            let trizettoData = filteredData.eligibilityDetail;
            let ruleTrizettoData = filteredData.runRuleDetail;
            // trizettoData.sort(function (a, b) {
            //     return new Date(b.currentTime) - new Date(a.currentTime);
            // })
            // let filteredIds = [];
            // trizettoData.forEach(el => {
            //     filteredIds.push(el.instanceID);
            //     // console.log(filteredIds)
            // });
            ruleTrizettoData.sort(function (a, b) {
                return new Date(b.currentTime) - new Date(a.currentTime);
            })
            this.setState({
                // rulesData: sortedEncounters ? sortedEncounters : [],
                rulesData: rulesData ? rulesData : [],
                trizettoData: ruleTrizettoData,
                eligibilityData: trizettoData,
                // trizettoData: trizettoData,
                ruleSuccess: response.data.success
            });
        }).catch(err => {
            console.error(err);
        })
    }

    // function to show div
    handleShow = (event) => {
        this.setState({ showRule: !this.state.showRule });
    }

    // function to hide div
    handleHide = (event) => {
        this.setState({ showRule: false })
    }

    // function to handle cell redirect
    handleCell = (id, linkedStatus) => {
        const linkedData = this.state.rulesData.filter(f => f.EncounterID === id).map(item => {
            const obj = {}
            obj["pName"] = item.payerName;
            obj["pId"] = item.payerId;
            obj["linkedPayer"] = item.PrimaryInsurancePlanName;
            obj["linkedPayerId"] = item.PrimaryInsurancePolicyPlanID;

            return obj;
        })
        let body_data = {
            rulesRunStatus: this.state.ruleSuccess ? this.state.ruleSuccess : false
        }
        API.post(`getChargesFromLocalId/${id}`, body_data).then((response) => {
            // console.log(response.data);
            const chargeData = response.data;
            if (this.state.ruleSuccess === true) {
                this.props.history.push({ pathname: `/insurances/${linkedStatus}`, state: { chargeData: chargeData, linkedData: linkedData } });
            }
            this.setState({ chargeData: chargeData });
        }).catch(err => {
            console.error(err);
        });
    }

    // API to get charge data by encounter id on row click
    // handleRow = (id) => {
    //     let body_data = {
    //         rulesRunStatus: this.state.ruleSuccess ? this.state.ruleSuccess : false
    //     }
    //     API.post(`getChargesFromLocalId/${id}`, body_data).then((response) => {
    //         // console.log(response.data);
    //         const chargeData = response.data;
    //         // console.log(this.state.trizettoData);
    //         if (this.state.ruleSuccess === true) {
    //             let length = this.state.trizettoData.length;
    //             // console.log(length);
    //             // let trizettoRes = this.state.trizettoData.filter(item => item.EncounterID === chargeData[0].EncounterID);
    //             let chargeInstanceIds = [];
    //             chargeData.forEach(el => chargeInstanceIds.push(el.instanceID))
    //             // console.log(chargeInstanceIds)
    //             let trizettoRes = this.state.trizettoData.filter(item => chargeInstanceIds.includes(item.instanceID));
    //             // console.log(trizettoRes);
    //             localStorage.setItem('isReviewd', trizettoRes[0].isReviewed);
    //             sessionStorage.setItem('viewedDetail', '0' ) // set 0 for not viewed page and log of action will trigger
    //             this.props.history.push({
    //                 pathname: '/inspection-noviolation',
    //                 state: {
    //                     chargeData: chargeData,
    //                     trizettoData: trizettoRes,
    //                     trizettoDate: this.state.trizettoData[length - 1],
    //                     rulesRan: this.state.rulesRan
    //                 }
    //             });
    //         }
    //         this.setState({ chargeData: chargeData });
    //     }).catch(err => {
    //         console.error(err);
    //     });
    // }
    handleRow = (id) => {
        let body_data = {
            rulesRunStatus: this.state.ruleSuccess ? this.state.ruleSuccess : false
        }
        API.post(`getChargesFromLocalId/${id}`, body_data).then((response) => {
            // console.log(response.data);
            const chargeData = response.data;
            if (this.state.ruleSuccess === true) {
                let length = this.state.trizettoData.length;
                // let chargeInstanceIds = [];
                // chargeData.forEach(el => chargeInstanceIds.push(el.instanceID))
                // let trizettoRes = this.state.trizettoData.filter(item => chargeInstanceIds.includes(item.instanceID));
                // let eligibilityRes = this.state.eligibilityData.filter(item => chargeInstanceIds.includes(item.instanceID));
                let eligibilityRes = this.state.eligibilityData.filter(item => item.instanceID === id);
                console.log(this.state.eligibilityData, eligibilityRes)
                localStorage.setItem('isReviewd', eligibilityRes[0].isReviewed);
                sessionStorage.setItem('viewedDetail', '0' ) // set 0 for not viewed page and log of action will trigger
                this.props.history.push({
                    pathname: '/inspection-noviolation',
                    state: {
                        // chargeData: chargeData,
                        // trizettoData: trizettoRes,
                        chargeData: chargeData.chargeDetails[0],
                        allChargeData: chargeData.chargeDetails,
                        trizettoData: chargeData.runRuleDetails,
                        eligibilityData: eligibilityRes,
                        trizettoDate: this.state.trizettoData[length - 1],
                        rulesRan: this.state.rulesRan,
                        from: 'runRules'
                    }
                });
            }
            this.setState({ chargeData: chargeData });
        }).catch(err => {
            console.error(err);
        });
    }

    deleteSpecificCache = (cacheName) => {
        if ("caches" in window) {
            caches.delete(cacheName).then(function (res) {
                return res;
            });
        }
    }

    getCacheData = async (cache_name, key) => {

        var names = await caches.keys()
        const cacheStorage = await caches.open(cache_name);
        
        const cachedResponse = await cacheStorage.match(key);
        if(cachedResponse) {
            return await cachedResponse.json()
        } else {
            return false
        }
    }

    async componentDidMount() {
        // console.log(this.state.rulesDate)
        this.setState({ rulesData: [] })
        // console.log("rulesData", this.state.rulesData)
        // console.log("rulesDate", this.state.rulesDate)
        const formattedDate = moment(this.state.rulesDate).format('YYYY-MM-DD');
        // console.log("formattedDate", formattedDate)
        const oldPageDetail = await this.getCacheData('navigateBack', 'goReverse')
        if(oldPageDetail.goReverse){
            this.getRunRules(oldPageDetail.formattedRuleDate)
            this.setState({
                rulesDate: new Date(oldPageDetail.formattedRuleDate)
            })
            this.deleteSpecificCache('navigateBack')
        } else if (this.state.trizettoSuccess === true) {
            this.getRunRules(this.state.rulesDate);
        }
    }

    render() {
        const { showRule, rulesData, rulesDate, trizettoData, ruleSuccess, selectedColumn, sortData, eligibilityData } = this.state;
        // console.log('rulesData', rulesData, rulesDate, trizettoData)
        const { state } = this.props.location;
        // console.log(state);
        // console.log("rulesData", rulesData);
        // let encounterData = state.encounterData;
        // console.log(encounterData);
        // let trizetto = state.trizettoData;
        // console.log('data', this.state.rulesDate);
        let length = state.trizettoData.length;
        let runRulesDate = state.trizettoData[length - 1].currentTime;
        // console.log(state.trizettoData[length-1].currentDate);
        const rule = state.getLastRule[0];
        // console.log(rule);

        const showStyle = {
            width: 40,
            height: 60,
            backgroundColor: '#5CB02C',
            lineHeight: '12px',
            cursor: 'pointer',
            position: 'fixed',
            top: 100,
            right: 0,
            zIndex:3,
            borderTopLeftRadius: '9px',
            borderBottomLeftRadius: '9px'
        }
        const hideStyle = {
            width: 300,  
            height:'100%',   // 294
            overflow:'hidden',
            background: 'linear-gradient(196.38deg, #81C3D7 13.47%, #81C3D7 38.72%, #3A7CA5 92.18%)',
            border: '1px solid #878787',
            borderRadius:'9px',
            lineHeight: '12px',
            position: 'fixed',
            top: 100,
            right: 6,
            zIndex:0,
            transition:'all .2s ease'
        }
        const header = {
            backgroundColor: '#24476A',
            height: 110,
            width: '100%',     //294
            marginLeft: 0,
            borderRadius: '9px 9px 0px 0px'
        }
        const styles = {
            content: {
                width: '94%',
                marginTop: 118,
                marginLeft: 88,
                marginBottom: 80,
                padding:'0rem 1rem 1rem 1rem',
                border:'0px !important',
            }
            // content: {
            //     width: '97%',
            //     marginTop: 75,
            //     marginLeft: 70,
            //     marginBottom: 80
            // }
        }
        return (
            <section>
                <SideBar
                    totalClaims={state.encounterData}
                    totalFilteredClaims={rulesData}
                    runRulesDate={runRulesDate ? runRulesDate : null}
                    rulesDate={rulesDate}
                    ruleSuccess={this.state.ruleSuccess} />
                <div style={styles.content}>
                    <div className={showRule ? 'pt-4 table-responsive custom-size tableresponsivecustom opentoggle1' : 'pt-4 table-responsive custom-size tableresponsivecustom'}>
                        <table className="rule-table">
                            <thead>
                                <tr>
                                    <th className="first-header">
                                        <span className='font-face-futura' style={{marginLeft: 20}}>Status</span>
                                    </th>
                                    {columnHeader.map((col, index) => (
                                        <th className='text-center' key={index} >
                                            <span style={{color: selectedColumn === index ? '#79D346' : 'white'}} onClick={(id) => this.colorElement(index)}>
                                                <span
                                                type="button" 
                                                className='font-face-futura'                                   
                                                onClick={e => sortData ? this.onSort(e, col.key, 'desc') : this.onSort(e, col.key, 'asc')}>{col.name}</span>
                                            </span>
                                        </th>
                                    ))}
                                    <th className="text-center">
                                        <span type="button" className='mr-50 font-face-futura'>Rules Ran On</span>
                                    </th>
                                </tr>
                            </thead><br />
                            <tbody>
                                {((ruleSuccess === true) || (this.state.trizettoSuccess === true)) && rulesData.length > 0 ? rulesData.map((rules, idx) => {
                                    let patientName = rules.PatientName;
                                    patientName = patientName.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let providerName = rules.SchedulingProviderName;
                                    providerName = providerName.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let primary = rules.PrimaryInsurancePlanName;
                                    primary = primary.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let secondary = rules.SecondaryInsurancePlanName;
                                    secondary = secondary.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let location = rules.ServiceLocationName;
                                    location = location.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    return (
                                        <tr style={{ cursor: 'pointer' }} key={rules.inscanceID} className={rules?.isReviewed ? "table-row tbl-with-border" : "table-row"}>
                                            <td className="text-center">
                                                <div className='innerdata' style={{ marginLeft:'1rem', borderRadius:'9px 0 0 9px' }}>
                                                    {rules?.runEligibilityForPrimary === true ? 
                                                        <>{rules?.isPassed === true ? 
                                                            <CheckCircleIcon fontSize="large" style={{color: '#5CB02C'}} /> : 
                                                            <CancelIcon fontSize="large" style={{color: '#964D4D'}} />}
                                                        </> : 
                                                        <>{rules?.isPassed === true ? 
                                                            <CheckCircleIcon fontSize="large" style={{color: '#5CB02C'}} /> : 
                                                            <CancelIcon fontSize="large" style={{color: '#964D4D'}} />}
                                                        </>
                                                    }
                                                    {/* {trizettoData && trizettoData[idx].runEligibilityForPrimary === true ? 
                                                    <span>{trizettoData[idx].isPassed === true ?
                                                        <CheckCircleIcon fontSize="large" style={{ color: '#5CB02C' }} /> :
                                                        <CancelIcon fontSize="large" style={{ color: '#964D4D' }} />}</span> : 
                                                    <span>{trizettoData[idx].finalRuleSuccess === true ?
                                                        <CheckCircleIcon fontSize="large" style={{ color: '#5CB02C' }} /> :
                                                        <CancelIcon fontSize="large" style={{ color: '#964D4D' }} />}</span>
                                                    } */}
                                                    {/* {trizettoData && trizettoData[idx].isPassed === true ?
                                                        <CheckCircleIcon fontSize="large" style={{ color: '#5CB02C' }} /> :
                                                        <CancelIcon fontSize="large" style={{ color: '#964D4D' }} />} */}
                                                </div>
                                            </td>
                                            <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID)}>
                                                <div className='innerdata'>
                                                    <span title={rules.PatientName} className='one-line-ellipsis'>{patientName}</span>
                                                </div>
                                            </td>
                                            <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID)}><div className='innerdata'>{rules.EncounterID}</div></td>
                                            <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID)}>
                                                <div className='innerdata'>
                                                    <span title={rules.SchedulingProviderName} className='one-line-ellipsis'>{providerName}</span>
                                                </div>
                                            </td>
                                            <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID)}><div className='innerdata'>{rules.ServiceStartDate}</div></td>
                                            <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID)}><div className='innerdata'>{rules.ProcedureCode}</div></td>
                                            <td className="text-center" onClick={(id) => { this.handleCell(rules.instanceID, rules.PrimaryInsuranceLinkedStatus); localStorage.setItem("isPrimary", true) }}>
                                                <div className='innerdata'>
                                                    <div>
                                                        <p title={rules.PrimaryInsurancePlanName} className='dblock one-line-ellipsis'>{primary}</p>
                                                        <p
                                                            style={{ textDecoration: 'none', color: rules?.eligibilitySuccess === true ? '#5CB02C' : '#964D4D' }}>
                                                            {rules.PrimaryInsurancePlanName && rules?.eligibilitySuccess === true ? 'Active' : 
                                                            (rules.PrimaryInsurancePlanName && rules?.eligibilitySuccess === false && rules?.PrimaryInsuranceLinkedStatus === true && rules?.PrimaryInsurancePolicyNumber !== "") ? 'Not Active' : 'N/A'}
                                                            {/* {rules.PrimaryInsurancePlanName && rules?.eligibilitySuccess === true ? 'Active' : (rules.PrimaryInsurancePlanName && rules?.eligibilitySuccess === false) ? 'N/A' : 'Not Active'} */}
                                                            {/* {rules.PrimaryInsurancePlanName && trizettoData && trizettoData[idx].eligibilitySuccess === true ? 'Active' : (rules.PrimaryInsurancePlanName && rules.PrimaryInsuranceLinkedStatus !== true) || (rules.PrimaryInsurancePlanName && trizettoData && trizettoData[idx].eligibilitySuccess === false) ? 'Not Active' : ''} */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="text-center" 
                                            // onClick={(id) => { this.handleCell(rules.instanceID, rules.SecondaryInsuranceLinkedStatus); localStorage.setItem("isPrimary", false) }}
                                            >
                                                <div className='innerdata'>
                                                    <div>
                                                        <p title={rules.SecondaryInsurancePlanName} className='one-line-ellipsis'>{secondary}</p>
                                                        {/* <p 
                                                    style={{textDecoration: 'none', color: trizettoData && trizettoData[idx].eligibilitySuccess === true ? '#5CB02C' : '#964D4D'}}>
                                                        {rules.SecondaryInsurancePlanName && trizettoData && trizettoData[idx].eligibilitySuccess === true ? 'Active' : (rules.SecondaryInsurancePlanName && rules.SecondaryInsuranceLinkedStatus !== true) || (rules.SecondaryInsurancePlanName && trizettoData && trizettoData[idx].eligibilitySuccess === false) ? 'Not Active' : ''}
                                                    </p> */}
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID)}>
                                                <div className='innerdata'>
                                                    <span title={rules.ServiceLocationName} className='one-line-ellipsis'>{location}</span>
                                                </div>
                                            </td>
                                            <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID)}><div className='innerdata'>{rules.EncounterDiagnosisID1}</div></td>
                                            <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID)}>
                                                <div className='innerdata' style={{ borderRadius:'0 9px 9px 0', marginRight: '-10px' }}>
                                                    <span title={new Date(rules?.created_at).toLocaleString('en-US')} className='one-line-ellipsis'>{new Date(rules?.created_at).toLocaleString('en-US')}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : (<tr><td colSpan={6} className="mt-3 noRecord text-center">No records found!</td></tr>)}

                                {/* {(ruleSuccess === true || this.state.trizettoSuccess === true) && */}
                                {/* {ruleSuccess !== true ? encounterData.map((encounter, idx) => {
                                    return(
                                    <TableRow style={{ cursor: 'pointer' }} key={encounter.EncounterID}>
                                        <TableCell className="text-center">
                                            <IconButton>
                                                {trizetto && trizetto[idx].isPassed === true ? 
                                                <CheckCircleIcon fontSize="large" color="success" /> : 
                                                <CancelIcon fontSize="large" color="error" />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(encounter.EncounterID)}>{encounter.PatientName}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(encounter.EncounterID)}>{encounter.EncounterID}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(encounter.EncounterID)}>{encounter.SchedulingProviderName}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(encounter.EncounterID)}>{encounter.ServiceStartDate}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(encounter.EncounterID)}>{encounter.ProcedureCode}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => {this.handleCell(encounter.EncounterID);localStorage.setItem("isPrimary",true)}}>
                                            {encounter.PrimaryInsurancePlanName}<br />
                                            <p 
                                            style={{textDecoration: 'none', color: trizetto && trizetto[idx].eligibilitySuccess === true ? 'teal' : 'red'}}>
                                                {encounter.PrimaryInsurancePlanName && trizetto && trizetto[idx].eligibilitySuccess === true ? 'Active' : (encounter.PrimaryInsurancePlanName && encounter.PrimaryInsuranceLinkedStatus === true && trizetto && trizetto[idx].eligibilitySuccess === false) ? 'Not Active' : (encounter.PrimaryInsurancePlanName && encounter.PrimaryInsuranceLinkedStatus !== true) ? 'N/A' : ''}
                                            </p>
                                        </TableCell>

                                        <TableCell className="text-center" onClick={(id) => {this.handleCell(encounter.EncounterID);localStorage.setItem("isPrimary",false)}}>
                                            {encounter.SecondaryInsurancePlanName}<br />
                                            <p 
                                            style={{textDecoration: 'none', color: trizetto && trizetto[idx].eligibilitySuccess === true ? 'teal' : 'red'}}>
                                                {encounter.SecondaryInsurancePlanName && trizetto && trizetto[idx].eligibilitySuccess === true ? 'Active' : (encounter.SecondaryInsurancePlanName && encounter.SecondaryInsuranceLinkedStatus === true && trizetto && trizetto[idx].eligibilitySuccess === false) ? 'Not Active' : (encounter.SecondaryInsurancePlanName && encounter.SecondaryInsuranceLinkedStatus !== true) ? 'N/A' : ''}
                                            </p>
                                        </TableCell>

                                        <TableCell className="text-center" onClick={(id) => this.handleRow(encounter.EncounterID)}>{encounter.ServiceLocationName}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(encounter.EncounterID)}>{encounter.EncounterDiagnosisID1}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(encounter.EncounterID)}>{new Date(runRulesDate).toLocaleString('en-US')}</TableCell>
                                    </TableRow>
                                )}) : rulesData.map((rules, idx) => {
                                    return(
                                    <TableRow style={{ cursor: 'pointer' }} key={rules.EncounterID}>
                                        <TableCell className="text-center">
                                            <IconButton>
                                                {trizettoData && trizettoData[idx].isPassed === true ? 
                                                <CheckCircleIcon fontSize="large" color="success" /> : 
                                                <CancelIcon fontSize="large" color="error" />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(rules.EncounterID)}>{rules.PatientName}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(rules.EncounterID)}>{rules.EncounterID}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(rules.EncounterID)}>{rules.SchedulingProviderName}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(rules.EncounterID)}>{rules.ServiceStartDate}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(rules.EncounterID)}>{rules.ProcedureCode}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => {this.handleCell(rules.EncounterID);localStorage.setItem("isPrimary",true)}}>
                                            {rules.PrimaryInsurancePlanName}<br />
                                            <p 
                                            style={{textDecoration: 'none', color: trizettoData && trizettoData[idx].eligibilitySuccess === true ? 'teal' : 'red'}}>
                                                {rules.PrimaryInsurancePlanName && trizettoData && trizettoData[idx].eligibilitySuccess === true ? 'Active' : (rules.PrimaryInsurancePlanName && rules.PrimaryInsuranceLinkedStatus !== true) || (rules.PrimaryInsurancePlanName && trizettoData && trizettoData[idx].eligibilitySuccess === false) ? 'Not Active' : ''}
                                            </p>
                                        </TableCell>

                                        <TableCell className="text-center" onClick={(id) => {this.handleCell(rules.EncounterID);localStorage.setItem("isPrimary",false)}}>
                                            {rules.SecondaryInsurancePlanName}<br />
                                            <p 
                                            style={{textDecoration: 'none', color: trizettoData && trizettoData[idx].eligibilitySuccess === true ? 'teal' : 'red'}}>
                                                {rules.SecondaryInsurancePlanName && trizettoData && trizettoData[idx].eligibilitySuccess === true ? 'Active' : (rules.SecondaryInsurancePlanName && rules.SecondaryInsuranceLinkedStatus !== true) || (rules.SecondaryInsurancePlanName && trizettoData && trizettoData[idx].eligibilitySuccess === false) ? 'Not Active' : ''}
                                            </p>
                                        </TableCell>

                                        <TableCell className="text-center" onClick={(id) => this.handleRow(rules.EncounterID)}>{rules.ServiceLocationName}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(rules.EncounterID)}>{rules.EncounterDiagnosisID1}</TableCell>
                                        <TableCell className="text-center" onClick={(id) => this.handleRow(rules.EncounterID)}>{new Date(trizettoData[idx].currentTime).toLocaleString('en-US')}</TableCell>
                                    </TableRow>
                                )})} */}
                            </tbody>
                        </table>
                        
                        <div className={showRule ? 'rule-footer opentoggle2' : 'rule-footer'}>
                            <div className='foot-flex'>
                                <div className='foot-left'>
                                    {/* <Button 
                                    variant="contained"
                                    className="text-white p-2 text-capitalize rulesRan" 
                                    disabled><span className='font-face-futura' style={{fontSize: 14}}>Rules Ran</span></Button>
                                    <div class="round-check">
                                        <input type="checkbox" id="check-round" />
                                        <label for="check-round"></label>
                                        <span className='text-dark font-face-futura dlock'>Auto-run Rules</span>
                                    </div> */}
                                </div>
                                <div className='foot-right'>
                                    <div>
                                        <span className="text-dark font-face-futura" style={{marginLeft: 180}}>
                                            <b>Total Claims:</b> {rulesData.length}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <Box>
                            {showRule ? (
                                    <div style={hideStyle}>
                                        <header className="row text-white font-face-futura" style={header}>
                                            <div className="col-10 mt-2">
                                                <h5 className="mostR">MOST<br />RECENT RULE</h5>
                                                <small className='small-label font-face-futura'>See what you created with us last to help test it with your current work list</small>
                                            </div>                                         
                                            
                                            <div className="col-2" style={{ cursor: 'pointer' }} onClick={this.handleHide}>
                                                <RemoveRedEyeIcon className="mt-2" /><br />
                                                <span style={{ fontSize: 12 }}>Hide</span>
                                            </div>
                                        </header>
                                        <div className="font-face-futura ruleStyle">
                                            <h6 className="m-1" style={{ fontSize: 18 }}>Rule Name
                                                <div className="mt-1" style={{ width: 85, borderBottom: '1px solid #0080A8' }}></div></h6>
                                            <p className="mt-2 mx-1 mb-2" style={{ fontSize: 14 }}>{rule?.ruleName}</p>
                                            <div className="bottomDivider"></div>

                                            <div className='rule-box1'>
                                                <h6 className="m-1" style={{ fontSize: 18 }}>Rule
                                                <div className="mt-1" style={{ width: 32, borderBottom: '1px solid #0080A8' }}></div></h6>
                                                <p className="m-2" style={{ fontSize: 14, lineHeight: 1.5 }}>
                                                    <span>{rule ? 'When the' : ''}</span> {' '}
                                                    {rule?.ruleInfo.map((item, idx) => {
                                                    // console.log(item.values)
                                                    let finalValues = (item.condition === 'is' || item.condition === 'contains') ? (item.values.map(val => val.name ? val.name : val)).join(' or ') : (item.values.map(val => val.name ? val.name : val)).join(' and ');
                                                    // console.log(finalValues)
                                                    return <span>{(idx > 0 ? ' the ' + item.category : item.category) + ' ' + item.condition + ' ' + finalValues + ' ' + (item.operator).toLowerCase()}
                                                    </span>})} {rule?.result}<span>{rule ? '.' : ''}</span>
                                                </p>
                                                <div className="bottomDivider"></div>

                                                <h6 className="m-1" style={{ fontSize: 18 }}>Reason
                                                    <div className="mt-1" style={{ width: 55, borderBottom: '1px solid #0080A8' }}></div></h6>
                                                <p className="mt-2 mx-1" style={{ fontSize: 14 }}>{rule?.Reason}<span>{rule ? '.' : ''}</span></p>
                                                <div className="bottomDivider"></div>
                                            </div>

                                            <h6 className="m-1" style={{ fontSize: 18 }}>Info
                                                <div className="mt-1" style={{ width: 28, borderBottom: '1px solid #0080A8' }}></div></h6>
                                            <p className="mt-2 mx-1 font-face-futura" style={{ fontSize: 14, lineHeight:'1rem' }}><strong>Tags:</strong> {rule?.tags.length > 0 && (rule.tags).join(', ')}</p>
                                            <p className="mt-2 mx-1 font-face-futura" style={{ fontSize: 14, lineHeight:'1rem' }}><strong>Created On:</strong> {(new Date(rule?.created_at)).toLocaleDateString()}</p>
                                            <p className="mt-2 mx-1 font-face-futura" style={{ fontSize: 14, lineHeight:'1rem' }}><strong>Created By:</strong> Loren Dilger</p>
                                            <div className="bottomDivider"></div>

                                            <div align="center" class="mrtop-55">
                                                <Button className="text-white ruleBtn" variant="contained">
                                                    <span className='font-face-futura'>Edit</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-white" style={showStyle} onClick={this.handleShow}>
                                        <VisibilityOffIcon className="mt-1" /><br />
                                        <span className='font-face-futura' style={{ fontSize: 12 }}>Last <br /> Rule</span>
                                    </div>
                                )}
                        </Box>
                    </div>
                </div>

                <Footer
                    rulesRan={state.rulesRan}
                    // rulesDate={this.state.rulesDate}
                    // formattedRulesDate={this.state.formattedRulesDate}
                    onSelectChange={this.handleSelectChange}
                    searchValue={this.state.searchValue}
                    onInputChange={this.handleInputChange}
                    rulesDate={new Date (rulesDate)}
                    handleDateChange={(e) => this.handleDateChange(e, this.state.rulesDate)}
                />
            </section>
        )
    }
}

export default withRouter(RunRules);